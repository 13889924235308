export var centers = [
    {
      name: 'spb',
      coord: [30.1630, 59.9505]
    },
    {
      name: 'savonlinna',
      coord: [28.89820716, 61.86618541]
    },
    {
      name: 'porvoo',
      coord: [25.6599, 60.3959]
    },
    {
      name: 'helsinki',
      coord: [24.9348, 60.1835]
    },
    {
      name: 'moscow',
      coord: [37.6172, 55.7526]
    },
    {
      name: 'kazan',
      coord: [49.1254, 55.7955]
    },
    {
      name: 'orenburg',
      coord: [55.1006, 51.7658]
    },
    {
      name: 'tallin',
      coord: [24.7508, 59.4444]
    },
    {
      name: 'roma',
      coord: [12.4952, 41.9037]
    },
    {
      name: 'prague',
      coord: [14.4201, 50.0875]
    },
    {
      name: 'petrozavodsk',
      coord: [34.37241327, 61.79371577]
    },
    {
      name: 'kaliningrad',
      coord: [20.47158634, 54.67721566]
    },
    {
      name: 'minsk',
      coord: [27.52965831, 53.92985736]
    },
    {
      name: 'stockholm',
      coord: [18.0711, 59.3251]
    }
    
];

export var points = [
  {
    "name": "Савонлинна",
    "coord": [
      28.89820716,
      61.86618541
    ],
    "date": "18.06.2015",
    "img": "2015_06_18_2"
  },
  {
    "name": "Керимяки",
    "coord": [
      29.28253552,
      61.91257158
    ],
    "date": "18.06.2015",
    "img": "2015_06_18_1"
  },
  {
    "name": "Комаровский берег",
    "coord": [
      29.80099578,
      60.18101626
    ],
    "date": "05.09.2015",
    "img": "2015_09_05_1"
  },
  {
    "name": "Шуваловский парк",
    "coord": [
      30.2944364,
      60.07424911
    ],
    "date": "19.09.2015",
    "img": "2015_09_19_1"
  },
  {
    "name": "Горьковская",
    "coord": [
      30.30713934,
      59.95757567
    ],
    "date": "24.09.2015",
    "img": "2015_09_24_1"
  },
  {
    "name": "Крестовский, пристань",
    "coord": [
      30.22390504,
      59.96862796
    ],
    "date": "27.09.2015",
    "img": "2015_09_27_1"
  },
  {
    "name": "Пискаревский парк",
    "coord": [
      30.39655346,
      60.0018807
    ],
    "date": "01.10.2015",
    "img": "2015_10_01_1"
  },
  {
    "name": "Сосновка",
    "coord": [
      30.33449788,
      60.01863395
    ],
    "date": "10.10.2015",
    "img": "2015_10_10_1"
  },
  {
    "name": "Павловск",
    "coord": [
      30.4341473,
      59.69331342
    ],
    "date": "17.10.2015",
    "img": "2015_10_17_1"
  },
  {
    "name": "Муринский парк",
    "coord": [
      30.39127488,
      60.03096286
    ],
    "date": "22.10.2015",
    "img": "2015_10_22_1"
  },
  {
    "name": "У Смольного",
    "coord": [
      30.3932,
      59.9468
    ],
    "date": "29.11.2015",
    "img": "2015_11_29_1"
  },
  {
    "name": "Пушкин",
    "coord": [
      30.42871851,
      59.72317496
    ],
    "date": "13.12.2015",
    "img": "2015_12_13_1"
  },
  {
    "name": "Петроградская, 1-й мед",
    "coord": [
      30.3319015,
      59.96751108
    ],
    "date": "24.01.2016",
    "img": "2016_01_24_1"
  },
  {
    "name": "Горьковская",
    "coord": [
      30.3238656,
      59.95290231
    ],
    "date": "31.01.2016",
    "img": "2016_01_31_1"
  },
  {
    "name": "Ржевский лесопарк",
    "coord": [
      30.499443,
      59.95154853
    ],
    "date": "06.02.2016",
    "img": "2016_02_06_1"
  },
  {
    "name": "Невский лесопарк",
    "coord": [
      30.57012457,
      59.82545852
    ],
    "date": "14.02.2016",
    "img": "2016_02_14_1"
  },
  {
    "name": "Фонтанка, Никольский собор",
    "coord": [
      30.3053369,
      59.9191594
    ],
    "date": "22.02.2016",
    "img": "2016_02_22_1"
  },
  {
    "name": "Приютино",
    "coord": [
      30.5757,
      60.0122
    ],
    "date": "29.02.2016",
    "img": "2016_02_29_1"
  },
  {
    "name": "Васильевский, Камская",
    "coord": [
      30.25710005,
      59.94692805
    ],
    "date": "13.03.2016",
    "img": "2016_03_13_1"
  },
  {
    "name": "Коломна, Матисов остров",
    "coord": [
      30.28415818,
      59.92392354
    ],
    "date": "20.03.2016",
    "img": "2016_03_20_1"
  },
  {
    "name": "Охта",
    "coord": [
      30.40692825,
      59.93372928
    ],
    "date": "23.03.2016",
    "img": "2016_03_23_1"
  },
  {
    "name": "Технологический институт",
    "coord": [
      30.32846827,
      59.91192046
    ],
    "date": "26.03.2016",
    "img": "2016_03_26_1"
  },
  {
    "name": "Нарвская, Трефолева",
    "coord": [
      30.27166981,
      59.89047944
    ],
    "date": "03.04.2016",
    "img": "2016_04_03_1"
  },
  {
    "name": "Невская застава",
    "coord": [
      30.46161312,
      59.86551295
    ],
    "date": "16.04.2016",
    "img": "2016_04_16_1"
  },
  {
    "name": "Лесотехническая академия",
    "coord": [
      30.3545608,
      59.99456324
    ],
    "date": "23.04.2016",
    "img": "2016_04_23_1"
  },
  {
    "name": "Черная речка",
    "coord": [
      30.27778525,
      59.98714757
    ],
    "date": "03.05.2016",
    "img": "2016_05_03_1"
  },
  {
    "name": "Южно-приморский парк",
    "coord": [
      30.17528195,
      59.84721634
    ],
    "date": "08.05.2016",
    "img": "2016_05_08_3"
  },
  {
    "name": "Ломоносов, пристань",
    "coord": [
      29.77775712,
      59.92651503
    ],
    "date": "08.05.2016",
    "img": "2016_05_08_2"
  },
  {
    "name": "Сергиевка",
    "coord": [
      29.84556337,
      59.89561902
    ],
    "date": "08.05.2016",
    "img": "2016_05_08_1"
  },
  {
    "name": "Летний сад, Михайловский сад, Моховая, Литейный",
    "coord": [
      30.34853119,
      59.9445316
    ],
    "date": "14.05.2016",
    "img": "2016_05_14_1"
  },
  {
    "name": "Парк 300-летия",
    "coord": [
      30.19300599,
      59.98562345
    ],
    "date": "22.05.2016",
    "img": "2016_05_22_1"
  },
  {
    "name": "Яппиля",
    "coord": [
      29.15339252,
      60.22278435
    ],
    "date": "17.07.2016",
    "img": "2016_07_17_2"
  },
  {
    "name": "Мыс Флотский",
    "coord": [
      29.15776989,
      60.16595734
    ],
    "date": "17.07.2016",
    "img": "2016_07_17_1"
  },
  {
    "name": "Бакунина - Таврический",
    "coord": [
      30.38446206,
      59.93095558
    ],
    "date": "19.07.2016",
    "img": "2016_07_19_1"
  },
  {
    "name": "Васильевский, куклы",
    "coord": [
      30.28618593,
      59.94538059
    ],
    "date": "21.08.2016",
    "img": "2016_08_21_1"
  },
  {
    "name": "Пл. Тургенева, Садовая",
    "coord": [
      30.28159399,
      59.91677169
    ],
    "date": "28.08.2016",
    "img": "2016_08_28_1"
  },
  {
    "name": "Канал Грибоедова, ул. Глинки",
    "coord": [
      30.2991249,
      59.92360093
    ],
    "date": "28.08.2016",
    "img": "2016_08_28_2"
  },
  {
    "name": "Фонтанка, Садовая",
    "coord": [
      30.32573242,
      59.92608492
    ],
    "date": "03.09.2016",
    "img": "2016_09_03_1"
  },
  {
    "name": "Суйда",
    "coord": [
      30.12246389,
      59.46707871
    ],
    "date": "18.09.2016",
    "img": "2016_09_18_1"
  },
  {
    "name": "Гатчина",
    "coord": [
      30.13566036,
      59.56451469
    ],
    "date": "18.09.2016",
    "img": "2016_09_18_2"
  },
  {
    "name": "Кронштадт",
    "coord": [
      29.8047616,
      59.98288092
    ],
    "date": "25.09.2016",
    "img": "2016_09_25_1"
  },
  {
    "name": "Приютино",
    "coord": [
      30.58294553,
      60.01280559
    ],
    "date": "02.10.2016",
    "img": "2016_10_02_1"
  },
  {
    "name": "Выборг",
    "coord": [
      28.75048182,
      60.71556591
    ],
    "date": "08.10.2016",
    "img": "2016_10_08_1"
  },
  {
    "name": "Екатерингоф",
    "coord": [
      30.25861282,
      59.90645526
    ],
    "date": "15.10.2016",
    "img": "2016_10_15_1"
  },
  {
    "name": "Нарвская, парк 9 января",
    "coord": [
      30.2750494,
      59.89952564
    ],
    "date": "15.10.2016",
    "img": "2016_10_15_2"
  },
  {
    "name": "Парк Победы",
    "coord": [
      30.32049675,
      59.87172806
    ],
    "date": "16.10.2016",
    "img": "2016_10_16_1"
  },
  {
    "name": "Парк Бабушкина",
    "coord": [
      30.4368617,
      59.88105931
    ],
    "date": "23.10.2016",
    "img": "2016_10_23_1"
  },
  {
    "name": "Куракина дача",
    "coord": [
      30.4472043,
      59.87291817
    ],
    "date": "23.10.2016",
    "img": "2016_10_23_2"
  },
  {
    "name": "Бакунина, Перекупной",
    "coord": [
      30.37601847,
      59.9300901
    ],
    "date": "23.10.2016",
    "img": "2016_10_23_3"
  },
  {
    "name": "Охта",
    "coord": [
      30.41087646,
      59.94301089
    ],
    "date": "05.11.2016",
    "img": "2016_11_05_1"
  },
  {
    "name": "Каменноостровский дворец",
    "coord": [
      30.30201096,
      59.97859763
    ],
    "date": "13.11.2016",
    "img": "2016_11_13_1"
  },
  {
    "name": "Кронштадт, Морской музей",
    "coord": [
      29.76179262,
      59.99629619
    ],
    "date": "27.11.2016",
    "img": "2016_11_27_1"
  },
  {
    "name": "Мойка, 12",
    "coord": [
      30.3205933,
      59.94112468
    ],
    "date": "12.11.2016",
    "img": "2016_12_11_1"
  },
  {
    "name": "Васильевский",
    "coord": [
      30.21240372,
      59.93739494
    ],
    "date": "03.12.2016",
    "img": "2016_12_03_1"
  },
  {
    "name": "Смольный",
    "coord": [
      30.3956,
      59.9483
    ],
    "date": "01.01.2017",
    "img": "2017_01_01_1"
  },
  {
    "name": "Технологический институт",
    "coord": [
      30.31817932,
      59.91167842
    ],
    "date": "15.01.2017",
    "img": "2017_01_15_1"
  },
  {
    "name": "Муринский парк",
    "coord": [
      30.41036147,
      60.02564055
    ],
    "date": "25.01.2017",
    "img": "2017_01_25_1"
  },
  {
    "name": "Гавань",
    "coord": [
      30.24535198,
      59.92815477
    ],
    "date": "05.02.2017",
    "img": "2017_02_05_1"
  },
  {
    "name": "Удельная",
    "coord": [
      30.32699842,
      60.00497565
    ],
    "date": "12.02.2017",
    "img": "2017_02_12_1"
  },
  {
    "name": "Достоевская, ул. Правды, дворы",
    "coord": [
      30.33807058,
      59.92100384
    ],
    "date": "18.02.2017",
    "img": "2017_02_18_1"
  },
  {
    "name": "Рыбацкое",
    "coord": [
      30.50767202,
      59.84040373
    ],
    "date": "05.03.2017",
    "img": "2017_03_05_1"
  },
  {
    "name": "Таврический",
    "coord": [
      30.37827153,
      59.9469388
    ],
    "date": "08.03.2017",
    "img": "2017_03_08_1"
  },
  {
    "name": "Горьковская, Петропавловская крепость",
    "coord": [
      30.30945677,
      59.95608242
    ],
    "date": "12.03.2017",
    "img": "2017_03_12_1"
  },
  {
    "name": "Охта",
    "coord": [
      30.41518945,
      59.94261323
    ],
    "date": "30.03.2017",
    "img": "2017_03_30_1"
  },
  {
    "name": "Лиговский пр.",
    "coord": [
      30.35558004,
      59.91676631
    ],
    "date": "08.04.2017",
    "img": "2017_04_08_1"
  },
  {
    "name": "Нарвская, Кировский завод",
    "coord": [
      30.25662799,
      59.87073717
    ],
    "date": "09.04.2017",
    "img": "2017_04_09_1"
  },
  {
    "name": "Васильевский, трамвайный парк",
    "coord": [
      30.25343079,
      59.93577178
    ],
    "date": "23.04.2017",
    "img": "2017_04_23_1"
  },
  {
    "name": "Петровский остров",
    "coord": [
      30.26679892,
      59.95947706
    ],
    "date": "30.04.2017",
    "img": "2017_04_30_1"
  },
  {
    "name": "Вокруг Ботанического сада",
    "coord": [
      30.31812567,
      59.96811248
    ],
    "date": "30.04.2017",
    "img": "2017_04_30_2"
  },
  {
    "name": "Фонтанка, Рижский",
    "coord": [
      30.29761214,
      59.9124153
    ],
    "date": "01.05.2017",
    "img": "2017_05_01_1"
  },
  {
    "name": "Реповеси",
    "coord": [
      26.83616782,
      61.15890296
    ],
    "date": "07.05.2017",
    "img": "2017_05_07_1"
  },
  {
    "name": "Миккели",
    "coord": [
      27.2722199,
      61.68859807
    ],
    "date": "07.05.2017",
    "img": "2017_05_07_2"
  },
  {
    "name": "Усть-Ижора",
    "coord": [
      30.6004228,
      59.80459304
    ],
    "date": "09.05.2017",
    "img": "2017_05_09_1"
  },
  {
    "name": "Корчмино (за Усть-Ижорой)",
    "coord": [
      30.66022533,
      59.78591199
    ],
    "date": "09.05.2017",
    "img": "2017_05_09_2"
  },
  {
    "name": "Шлиссельбург",
    "coord": [
      31.03649634,
      59.94351063
    ],
    "date": "09.05.2017",
    "img": "2017_05_09_3"
  },
  {
    "name": "Нарвская",
    "coord": [
      30.27194876,
      59.90132274
    ],
    "date": "13.05.2017",
    "img": "2017_05_13_1"
  },
  {
    "name": "Лисий нос",
    "coord": [
      30.01973529,
      60.00141402
    ],
    "date": "14.05.2017",
    "img": "2017_05_14_1"
  },
  {
    "name": "Невская губа",
    "coord": [
      30.06906648,
      60.00290523
    ],
    "date": "14.05.2017",
    "img": "2017_05_14_2"
  },
  {
    "name": "Сосновый бор, пристань",
    "coord": [
      29.05482671,
      59.89422522
    ],
    "date": "21.05.2017",
    "img": "2017_05_21_6"
  },
  {
    "name": "Сосновый бор",
    "coord": [
      29.0836229,
      59.88921999
    ],
    "date": "21.05.2017",
    "img": "2017_05_21_5"
  },
  {
    "name": "Сосновый бор, Андерсенград",
    "coord": [
      29.08695957,
      59.89484948
    ],
    "date": "21.05.2017",
    "img": "2017_05_21_1"
  },
  {
    "name": "Копорье",
    "coord": [
      29.03241417,
      59.71119675
    ],
    "date": "21.05.2017",
    "img": "2017_05_21_2"
  },
  {
    "name": "Лопухинка",
    "coord": [
      29.40923234,
      59.73870131
    ],
    "date": "21.05.2017",
    "img": "2017_05_21_3"
  },
  {
    "name": "Гостилицы",
    "coord": [
      29.61568733,
      59.74562121
    ],
    "date": "21.05.2017",
    "img": "2017_05_21_4"
  },
  {
    "name": "Минск",
    "coord": [
      27.52965831,
      53.92985736
    ],
    "date": "25-27.05.2017",
    "img": "2017_05_27_1"
  },
  {
    "name": "Адмиралтейская",
    "coord": [
      30.31541128,
      59.93662099
    ],
    "date": "06.06.2017",
    "img": "2017_06_06_1"
  },
  {
    "name": "Ал. Невского и Синопская",
    "coord": [
      30.38250942,
      59.93283699
    ],
    "date": "10.06.2017",
    "img": "2017_06_10_1"
  },
  {
    "name": "Калининград",
    "coord": [
      20.47158634,
      54.67721566
    ],
    "date": "05.07.2017",
    "img": "2017_07_05_1"
  },
  {
    "name": "Советск",
    "coord": [
      21.87963876,
      55.0833885
    ],
    "date": "06.07.2017",
    "img": "2017_07_06_1"
  },
  {
    "name": "Куршская коса",
    "coord": [
      20.88795101,
      55.22198363
    ],
    "date": "07.07.2017",
    "img": "2017_07_07_1"
  },
  {
    "name": "Куршская коса 2",
    "coord": [
      20.85756694,
      55.18230813
    ],
    "date": "07.07.2017",
    "img": "2017_07_07_3"
  },
  {
    "name": "Куршская коса 3",
    "coord": [
      20.73213612,
      55.08899485
    ],
    "date": "07.07.2017",
    "img": "2017_07_07_2"
  },
  {
    "name": "Кивач",
    "coord": [
      33.98297799,
      62.26903888
    ],
    "date": "19.08.2017",
    "img": "2017_08_19_1"
  },
  {
    "name": "Гирвас",
    "coord": [
      33.6687733,
      62.48473052
    ],
    "date": "20.08.2017",
    "img": "2017_08_20_1"
  },
  {
    "name": "Петрозаводск",
    "coord": [
      34.37241327,
      61.79371577
    ],
    "date": "20.08.2017",
    "img": "2017_08_20_2"
  },
  {
    "name": "Благодатная",
    "coord": [
      30.3202929,
      59.87884654
    ],
    "date": "16.09.2017",
    "img": "2017_09_16_1"
  },
  {
    "name": "Волковская",
    "coord": [
      30.36524672,
      59.90147339
    ],
    "date": "16.09.2017",
    "img": "2017_09_16_2"
  },
  {
    "name": "Петроградская - Спортивная",
    "coord": [
      30.31132359,
      59.96703317
    ],
    "date": "17.09.2017",
    "img": "2017_09_17_1"
  },
  {
    "name": "Канонерский остров",
    "coord": [
      30.21056909,
      59.89431133
    ],
    "date": "23.09.2017",
    "img": "2017_09_23_1"
  },
  {
    "name": "Пулковская обсерватория",
    "coord": [
      30.33402581,
      59.77157431
    ],
    "date": "14.10.2017",
    "img": "2017_10_14_1"
  },
  {
    "name": "Спортивная",
    "coord": [
      30.29111046,
      59.9515163
    ],
    "date": "07.10.2017",
    "img": "2017_10_07_1"
  },
  {
    "name": "Охта",
    "coord": [
      30.41350502,
      59.94268309
    ],
    "date": "24.12.2015",
    "img": "2015_12_24_1"
  },
  {
    "name": "Пушкин",
    "coord": [
      30.40836591,
      59.71559034
    ],
    "date": "21.10.2017",
    "img": "2017_10_21_1"
  },
  {
    "name": "Таллин",
    "coord": [
      24.78461055,
      59.44362207
    ],
    "date": "28-29.10.2017",
    "img": "2017_10_28_1"
  },
  {
    "name": "Химиков",
    "coord": [
      30.47977704,
      59.97140924
    ],
    "date": "04.11.2017",
    "img": "2017_11_04_1"
  },
  {
    "name": "Коломна",
    "coord": [
      30.28154034,
      59.92311702
    ],
    "date": "05.11.2017",
    "img": "2017_11_05_1"
  },
  {
    "name": "Лиговский",
    "coord": [
      30.35436768,
      59.921106
    ],
    "date": "11.11.2017",
    "img": "2017_11_11_1"
  },
  {
    "name": "Токсово",
    "coord": [
      30.54169,
      60.17869
    ],
    "date": "12.11.2017",
    "img": "2017_11_12_1"
  },
  {
    "name": "ЦПКиО",
    "coord": [
      30.25939602,
      59.97464662
    ],
    "date": "19.11.2017",
    "img": "2017_11_19_1"
  },
  {
    "name": "Музей воды",
    "coord": [
      30.37430186,
      59.94884617
    ],
    "date": "26.11.2017",
    "img": "2017_11_26_1"
  },
  {
    "name": "Пионерская площадь",
    "coord": [
      30.33097882,
      59.92170287
    ],
    "date": "26.11.2017",
    "img": "2017_11_26_2"
  },
  {
    "name": "Канал Грибоедова",
    "coord": [
      30.31712789,
      59.93015998
    ],
    "date": "17.12.2017",
    "img": "2017_12_17_1"
  },
  {
    "name": "Михайловский сад",
    "coord": [
      30.33503432,
      59.94159759
    ],
    "date": "01.01.2018",
    "img": "2018_01_01_1"
  },
  {
    "name": "Этажи",
    "coord": [
      30.35306949,
      59.92145014
    ],
    "date": "05.01.2018",
    "img": "2018_01_05_1"
  },
  {
    "name": "Невский лесопарк",
    "coord": [
      30.56664843,
      59.82639145
    ],
    "date": "08.01.2018",
    "img": "2018_01_08_1"
  },
  {
    "name": "Октябрьская наб.",
    "coord": [
      30.44666785,
      59.89301973
    ],
    "date": "14.01.2018",
    "img": "2018_01_14_1"
  },
  {
    "name": "Пушкин",
    "coord": [
      30.38539547,
      59.72954104
    ],
    "date": "20.01.2018",
    "img": "2018_01_20_1"
  },
  {
    "name": "Новая Голландия",
    "coord": [
      30.28549928,
      59.92828917
    ],
    "date": "21.01.2018",
    "img": "2018_01_21_1"
  },
  {
    "name": "Мурино",
    "coord": [
      30.42204518,
      60.05092058
    ],
    "date": "04.02.2018",
    "img": "2018_02_04_1"
  },
  {
    "name": "Шоссе Революции",
    "coord": [
      30.44933934,
      59.96041159
    ],
    "date": "10.02.2018",
    "img": "2018_02_10_1"
  },
  {
    "name": "Охта",
    "coord": [
      30.41508216,
      59.94096884
    ],
    "date": "17.02.2018",
    "img": "2018_02_17_1"
  },
  {
    "name": "Оранжерея",
    "coord": [
      30.3685,
      59.9484
    ],
    "date": "17.03.2018",
    "img": "2018_03_03_1"
  },
  {
    "name": "Звонница Смольного собора",
    "coord": [
      30.3954,
      59.9489
    ],
    "date": "03.03.2018",
    "img": "2018_03_03_2"
  },
  {
    "name": "Парк 300-летия",
    "coord": [
      30.2082,
      59.9797
    ],
    "date": "04.03.2018",
    "img": "2018_03_04_1"
  },
  {
    "name": "Котка",
    "coord": [
      26.9401,
      60.4472
    ],
    "date": "08.03.2018",
    "img": "2018_03_08_1"
  },
  {
    "name": "Ловииса",
    "coord": [
      26.2244,
      60.4566
    ],
    "date": "08.03.2018",
    "img": "2018_03_08_2"
  },
  {
    "name": "Порвоо",
    "coord": [
      25.6576,
      60.3975
    ],
    "date": "09.03.2018",
    "img": "2018_03_09_1"
  },
  {
    "name": "Коломна",
    "coord": [
      30.3007,
      59.9193
    ],
    "date": "08.04.2018",
    "img": "2018_04_08_1"
  },
  {
    "name": "Обводный, Курляндская",
    "coord": [
      30.2770,
      59.9116
    ],
    "date": "11.04.2018",
    "img": "2018_04_11_1"
  },
  {
    "name": "Набережная, прогулка с Олей",
    "coord": [
      30.3913,
      59.9595
    ],
    "date": "15.04.2018",
    "img": "2018_04_15_1"
  },
  {
    "name": "Вырица",
    "coord": [
      30.3413,
      59.4167
    ],
    "date": "22.04.2018",
    "img": "2018_04_22_1"
  },
  {
    "name": "Кронштадт",
    "coord": [
      29.6747,
      60.0284
    ],
    "date": "30.04.2018",
    "img": "2018_04_30_1"
  },
  {
    "name": "Кронштадт",
    "coord": [
      29.7542,
      60.0293
    ],
    "date": "30.04.2018",
    "img": "2018_04_30_2"
  },
  {
    "name": "Сестрорецк, пляж",
    "coord": [
      29.9455,
      60.1133
    ],
    "date": "27.05.2018",
    "img": "2018_05_27_1"
  },
  {
    "name": "Флоренция",
    "coord": [
      11.2548,
      43.7713
    ],
    "date": "27.06.2018",
    "img": "2018_06_27_1"
  },
  {
    "name": "Прато",
    "coord": [
      11.0914,
      43.8808
    ],
    "date": "28.06.2018",
    "img": "2018_06_28_1"
  },
  {
    "name": "Болонья",
    "coord": [
      11.3420,
      44.5091
    ],
    "date": "28.06.2018",
    "img": "2018_06_28_2"
  },
  {
    "name": "Верона",
    "coord": [
      10.9975,
      45.4438
    ],
    "date": "29.06.2018",
    "img": "2018_06_29_1"
  },
  {
    "name": "Венеция",
    "coord": [
      12.3261,
      45.4487
    ],
    "date": "29.06.2018",
    "img": "2018_06_29_2"
  },
  {
    "name": "Венеция",
    "coord": [
      12.3580,
      45.4277
    ],
    "date": "30.06.2018",
    "img": "2018_06_30_1"
  },
  {
    "name": "Сан-Марино",
    "coord": [
      12.4471,
      43.9350
    ],
    "date": "01.07.2018",
    "img": "2018_07_01_1"
  },
  {
    "name": "Римини",
    "coord": [
      12.6085,
      44.0444
    ],
    "date": "01.07.2018",
    "img": "2018_07_01_2"
  },
  {
    "name": "Рим и Ватикан",
    "coord": [
      12.4952,
      41.9037
    ],
    "date": "02.07.2018",
    "img": "2018_07_02_1"
  },
  {
    "name": "Кутна-Гора",
    "coord": [
      15.2680,
      49.9488
    ],
    "date": "28.07.2018",
    "img": "2018_07_28_1"
  },
  {
    "name": "Прага",
    "coord": [
      14.4201,
      50.0875
    ],
    "date": "28.07.2018 — 01.08.2018",
    "img": "2018_07_30_1"
  },
  {
    "name": "Зеленоградск",
    "coord": [
      20.4711,
      54.9604
    ],
    "date": "03.08.2018",
    "img": "2018_08_03_1"
  },
  {
    "name": "Светлогорск",
    "coord": [
      20.1467,
      54.9455
    ],
    "date": "04.08.2018",
    "img": "2018_08_04_1"
  },
  {
    "name": "Воейково и Лиголамби",
    "coord": [
      30.6868,
      59.9550
    ],
    "date": "11.08.2018",
    "img": "2018_08_11_1"
  },
  {
    "name": "Мурино",
    "coord": [
      30.4397,
      60.0511
    ],
    "date": "18.08.2018",
    "img": "2018_08_18_1"
  },
  {
    "name": "Выборская набережная",
    "coord": [
      30.3310,
      59.9754
    ],
    "date": "19.08.2018",
    "img": "2018_08_19_1"
  },
  {
    "name": "Новосаратовка, мост",
    "coord": [
      30.54913,
      59.83767
    ],
    "date": "15.09.2018",
    "img": "2018_09_15_1"
  },
  {
    "name": "Пулковская обсерватория",
    "coord": [
      30.3298,
      59.7721
    ],
    "date": "23.09.2018",
    "img": "2018_09_23_1"
  },
  {
    "name": "Ленсоветовский, ДР Вики",
    "coord": [
      30.4753,
      59.7563
    ],
    "date": "23.09.2018",
    "img": "2018_09_23_2"
  },
  {
    "name": "Медное озеро",
    "coord": [
      30.1468,
      60.1963
    ],
    "date": "29.09.2018",
    "img": "2018_09_29_1"
  },
  {
    "name": "Библиотека Тимирязева",
    "coord": [
      30.2953,
      59.9077
    ],
    "date": "05.10.2018",
    "img": "2018_10_05_1"
  },
  {
    "name": "Лес за Лехтуси",
    "coord": [
      30.5974,
      60.2986
    ],
    "date": "06.10.2018",
    "img": "2018_10_06_1"
  },
  {
    "name": "Пионерский парк",
    "coord": [
      30.4181,
      59.9830
    ],
    "date": "07.10.2018",
    "img": "2018_10_07_1"
  },
  {
    "name": "Гранитная улица",
    "coord": [
      30.4262,
      59.9286
    ],
    "date": "26.10.2018",
    "img": "2018_10_26_1"
  },
  {
    "name": "Великий Новгород",
    "coord": [
      31.2755,
      58.5212
    ],
    "date": "27-28.10.2018",
    "img": "2018_10_27_1"
  },
  {
    "name": "Юкки",
    "coord": [
      30.3022,
      60.1132
    ],
    "date": "04.11.2018",
    "img": "2018_11_04_1"
  },
  {
    "name": "Оз. Светлое",
    "coord": [
      30.46935,
      60.14667
    ],
    "date": "24.11.2018",
    "img": "2018_11_24_1"
  },
  {
    "name": "Пионерская площадь",
    "coord": [
      30.3374,
      59.9178
    ],
    "date": "01.12.2018",
    "img": "2018_12_01_1"
  },
  {
    "name": "Выборг",
    "coord": [
      28.7325,
      60.7115
    ],
    "date": "02.12.2018",
    "img": "2018_12_02_1"
  },
  {
    "name": "Тула",
    "coord": [
      37.6204,
      54.1951
    ],
    "date": "29.12.2018",
    "img": "2018_12_29_1"
  },
  {
    "name": "Богородицк",
    "coord": [
      38.1404,
      53.7718
    ],
    "date": "29.12.2018—02.01.2019",
    "img": "2018_12_29_2"
  },
  {
    "name": "Сосновка",
    "coord": [
      30.3593,
      60.0286
    ],
    "date": "06.01.2019",
    "img": "2019_01_06_01"
  },
  {
    "name": "Мурино",
    "coord": [
      30.4296,
      60.0441
    ],
    "date": "12.01.2019",
    "img": "2019_01_12_01"
  },
  {
    "name": "Мурино",
    "coord": [
      30.4546,
      60.0477
    ],
    "date": "13.01.2019",
    "img": "2019_01_13_01"
  },
  {
    "name": "Мурино",
    "coord": [
      30.4421,
      60.0567
    ],
    "date": "02.02.2019",
    "img": "2019_02_02_01"
  },
  {
    "name": "Невский лесопарк",
    "coord": [
      30.5702,
      59.8324
    ],
    "date": "09.02.2019",
    "img": "2019_02_09_01"
  },
  {
    "name": "Прогулка на Трамвае",
    "coord": [
      30.3727,
      60.0452
    ],
    "date": "17.02.2019",
    "img": "2019_02_17_01"
  },
  {
    "name": "Прогулка на Трамвае",
    "coord": [
      30.3908,
      60.0415
    ],
    "date": "17.02.2019",
    "img": "2019_02_17_02"
  },
  {
    "name": "Пр. Науки",
    "coord": [
      30.4290,
      60.0027
    ],
    "date": "17.02.2019",
    "img": "2019_02_17_03"
  },
  {
    "name": "Мега Парнас",
    "coord": [
      30.3805,
      60.0915
    ],
    "date": "17.02.2019",
    "img": "2019_02_17_04"
  },
  {
    "name": "Филфак РГПУ им. Герцена",
    "coord": [
      30.28425,
      59.94646
    ],
    "date": "02.03.2019",
    "img": "2019_03_02_01"
  },
  {
    "name": "Васильевский остров",
    "coord": [
      30.29016,
      59.94498
    ],
    "date": "02.03.2019",
    "img": "2019_03_02_02"
  },
  {
    "name": "Выставка котов",
    "coord": [
      30.4250,
      59.9319
    ],
    "date": "03.03.2019",
    "img": "2019_03_03_01"
  },
  {
    "name": "Хельсинки",
    "coord": [
      24.9307,
      60.1686
    ],
    "date": "07-08.03.2019",
    "img": "2019_03_08_01"
  },
  {
    "name": "Театр Дождей",
    "coord": [
      30.3131,
      59.9190
    ],
    "date": "14.03.2019",
    "img": "2019_03_14_01"
  },
  {
    "name": "Театр Эстрады",
    "coord": [
      30.32229,
      59.93709
    ],
    "date": "22.03.2019",
    "img": "2019_03_22_01"
  },
  {
    "name": "Елизаровская",
    "coord": [
      30.42814,
      59.89674
    ],
    "date": "24.03.2019",
    "img": "2019_03_24_01"
  },
  {
    "name": "Сердце, Princesse Angine",
    "coord": [
      30.29564,
      59.94370
    ],
    "date": "29.03.2019",
    "img": "2019_03_29_01"
  },
  {
    "name": "Республика котов",
    "coord": [
      30.34838,
      59.93380
    ],
    "date": "30.03.2019",
    "img": "2019_03_30_01"
  },
  {
    "name": "СПбГУ, Тотальный диктант",
    "coord": [
      30.29917,
      59.94155
    ],
    "date": "13.04.2019",
    "img": "2019_04_13_01"
  },
  {
    "name": "«Ясная поляна», выдача работ",
    "coord": [
      30.31384,
      59.96549
    ],
    "date": "21.04.2019",
    "img": "2019_04_21_1"
  },
  {
    "name": "Ботанический сад",
    "coord": [
      30.32678,
      59.97079
    ],
    "date": "21.04.2019",
    "img": "2019_04_21_2"
  },
  {
    "name": "Петрозаводск",
    "coord": [
      34.3718,
      61.7914
    ],
    "date": "01.06.2019",
    "img": "2019_06_01_1"
  },
  {
    "name": "Лосево",
    "coord": [
      30.00031,
      60.68785
    ],
    "date": "27.07.2019",
    "img": "2019_07_27_1"
  },
  {
    "name": "Москва, Сокольники",
    "coord": [
      37.68521,
      55.80080
    ],
    "date": "28.07.2019",
    "img": "2019_07_28_1"
  },
  {
    "name": "Москва, кафе Лебедева",
    "coord": [
      37.59496,
      55.75728
    ],
    "date": "28.07.2019",
    "img": "2019_07_28_2"
  },
  {
    "name": "Москва, бульвар",
    "coord": [
      37.61822,
      55.76766
    ],
    "date": "28.07.2019",
    "img": "2019_07_28_3"
  },
  {
    "name": "Владимир",
    "coord": [
      40.40795,
      56.12575
    ],
    "date": "29.07.2019",
    "img": "2019_07_29_1"
  },
  {
    "name": "Муром",
    "coord": [
      42.0518,
      55.5790
    ],
    "date": "29.07.2019",
    "img": "2019_07_29_2"
  },
  {
    "name": "Казань",
    "coord": [
      49.1058,
      55.8010
    ],
    "date": "30.07.2019",
    "img": "2019_07_30_1"
  },
  {
    "name": "Казань",
    "coord": [
      49.1177,
      55.7790
    ],
    "date": "30.07.2019",
    "img": "2019_07_30_2"
  },
  {
    "name": "Волга",
    "coord": [
      49.3190,
      55.1940
    ],
    "date": "01.08.2019",
    "img": "2019_08_01_1"
  },
  {
    "name": "Самара",
    "coord": [
      50.0968,
      53.1992
    ],
    "date": "02.08.2019",
    "img": "2019_08_02_1"
  },
  {
    "name": "Волга, шлюзы",
    "coord": [
      49.5624,
      53.4602
    ],
    "date": "02.08.2019",
    "img": "2019_08_02_2"
  },
  {
    "name": "Болгары",
    "coord": [
      49.0489,
      54.9852
    ],
    "date": "03.08.2019",
    "img": "2019_08_03_1"
  },
  {
    "name": "Казань",
    "coord": [
      49.1299,
      55.7998
    ],
    "date": "04.08.2019",
    "img": "2019_08_04_1"
  },
  {
    "name": "Бузулук",
    "coord": [
      52.26481,
      52.79211
    ],
    "date": "07.08.2019",
    "img": "2019_08_07_2"
  },
  {
    "name": "Оренбург",
    "coord": [
      55.1071,
      51.7532
    ],
    "date": "08.08.2019",
    "img": "2019_08_08_1"
  },
  {
    "name": "Оренбург",
    "coord": [
      55.1013,
      51.7590
    ],
    "date": "08.08.2019",
    "img": "2019_08_08_2"
  },
  {
    "name": "Москва",
    "coord": [
      37.66887,
      55.76969
    ],
    "date": "11.08.2019",
    "img": "2019_08_11_1"
  },
  {
    "name": "Центр",
    "coord": [
      30.3537,
      59.9424
    ],
    "date": "17.08.2019",
    "img": "2019_08_17_1"
  },
  {
    "name": "Сестрорецк",
    "coord": [
      29.94504,
      60.11567
    ],
    "date": "27.08.2019",
    "img": "2019_08_27_1"
  },
  {
    "name": "Лес за Матокской",
    "coord": [
      30.5947,
      60.3502
    ],
    "date": "15.09.2019",
    "img": "2019_09_15_1"
  },
  {
    "name": "Мурино, поля",
    "coord": [
      30.4072,
      60.0580
    ],
    "date": "19.09.2019",
    "img": "2019_09_19_1"
  },
  {
    "name": "Мурино, поля",
    "coord": [
      30.4117,
      60.0546
    ],
    "date": "25.09.2019",
    "img": "2019_09_25_1"
  },
  {
    "name": "Выборгский ЗАГС",
    "coord": [
      30.3464,
      59.9997
    ],
    "date": "03.10.2019",
    "img": "2019_10_03_1"
  },
  {
    "name": "Мичуринское",
    "coord": [
      29.8741,
      60.5692
    ],
    "date": "04-06.10.2019",
    "img": "2019_10_04_1"
  },
  {
    "name": "Сярьги, озеро",
    "coord": [
      30.47240,
      60.14777
    ],
    "date": "20.10.2019",
    "img": "2019_10_20_1"
  },
  {
    "name": "Юкии, озеро",
    "coord": [
      30.3048,
      60.1157
    ],
    "date": "23.10.2019",
    "img": "2019_10_23_1"
  },
  {
    "name": "Шуваловский парк",
    "coord": [
      30.30491,
      60.07459
    ],
    "date": "02.11.2019",
    "img": "2019_11_02_1"
  },
  {
    "name": "Охта",
    "coord": [
      30.4270,
      59.9555
    ],
    "date": "17.11.2019",
    "img": "2019_11_17_1"
  },
  {
    "name": "Токсово",
    "coord": [
      30.51372,
      60.15149
    ],
    "date": "17.11.2019",
    "img": "2019_11_23_1"
  },
  {
    "name": "Мурино, поле",
    "coord": [
      30.41295,
      60.05430
    ],
    "date": "24.11.2019",
    "img": "2019_11_24_1"
  },
  {
    "name": "Сосновка",
    "coord": [
      30.36422,
      60.02953
    ],
    "date": "14.12.2019",
    "img": "2019_12_14_1"
  },
  {
    "name": "Сестрорецк",
    "coord": [
      29.9495,
      60.1139
    ],
    "date": "06.01.2020",
    "img": "2020_01_06_1"
  },
  {
    "name": "Ладожское озеро",
    "coord": [
      31.0816,
      60.1194
    ],
    "date": "11.01.2020",
    "img": "2020_01_11_1"
  },
  {
    "name": "Озерки",
    "coord": [
      30.3136,
      60.0399
    ],
    "date": "18.01.2020",
    "img": "2020_01_18_1"
  },
  {
    "name": "Таврический",
    "coord": [
      30.37467,
      59.94620
    ],
    "date": "22.01.2020",
    "img": "2020_01_22_1"
  },
  {
    "name": "Канонерский остров",
    "coord": [
      30.20481,
      59.89149
    ],
    "date": "26.01.2020",
    "img": "2020_01_26_1"
  },
  {
    "name": "Нарвская",
    "coord": [
      30.26666,
      59.88867
    ],
    "date": "24.02.2020",
    "img": "2020_02_24_1"
  },
  {
    "name": "Коломна",
    "coord": [
      30.28800,
      59.91973
    ],
    "date": "07.03.2020",
    "img": "2020_03_07_1"
  },
  {
    "name": "Севкабель",
    "coord": [
      30.24017,
      59.92399
    ],
    "date": "08.03.2020",
    "img": "2020_03_08_1"
  },
  {
    "name": "Васильевский",
    "coord": [
      30.2429,
      59.9333
    ],
    "date": "08.03.2020",
    "img": "2020_03_08_2"
  },
  {
    "name": "Пр. Энгельса",
    "coord": [
      30.33053,
      60.00008
    ],
    "date": "08.03.2020",
    "img": "2020_03_08_3"
  },
  {
    "name": "Пески",
    "coord": [
      30.3679,
      59.9371
    ],
    "date": "09.03.2020",
    "img": "2020_03_09_1"
  },
  {
    "name": "Сярьги",
    "coord": [
      30.46927,
      60.14535
    ],
    "date": "15.03.2020",
    "img": "2020_03_15_1"
  },
  {
    "name": "Сосновка",
    "coord": [
      30.35409,
      60.02874
    ],
    "date": "19.03.2020",
    "img": "2020_03_19_1"
  },
  {
    "name": "Токсовские высоты",
    "coord": [
      30.5306,
      60.1677
    ],
    "date": "21.03.2020",
    "img": "2020_03_21_1"
  },
  {
    "name": "Кавголово",
    "coord": [
      30.54700,
      60.17961
    ],
    "date": "21.03.2020",
    "img": "2020_03_21_2"
  },
  {
    "name": "Оз. Вероярви",
    "coord": [
      30.5407,
      60.1497
    ],
    "date": "22.03.2020",
    "img": "2020_03_22_1"
  },
  {
    "name": "Оз. Хепоярви",
    "coord": [
      30.5716,
      60.1599
    ],
    "date": "28.03.2020",
    "img": "2020_03_28_1"
  },
  {
    "name": "Сярьги, оз. Светлое",
    "coord": [
      30.47272,
      60.14902
    ],
    "date": "07.04.2020",
    "img": "2020_04_07_1"
  },
  {
    "name": "Сярьги, оз. Светлое",
    "coord": [
      30.47723,
      60.14591
    ],
    "date": "11.04.2020",
    "img": "2020_04_11_1"
  },
  {
    "name": "Керро",
    "coord": [
      30.3208,
      60.3259
    ],
    "date": "25.04.2020",
    "img": "2020_04_25_1"
  },
  {
    "name": "Сярьги, зубробизоны",
    "coord": [
      30.4628,
      60.1541
    ],
    "date": "03.05.2020",
    "img": "2020_05_03_1"
  },
  {
    "name": "Сярьги, зубробизоны",
    "coord": [
      30.4662,
      60.1510
    ],
    "date": "06.05.2020",
    "img": "2020_05_06_1"
  },
  {
    "name": "Осиновая Роща",
    "coord": [
      30.2525,
      60.1157
    ],
    "date": "09.05.2020",
    "img": "2020_05_09_1"
  },
  {
    "name": "Дибуны, озеро",
    "coord": [
      30.1315,
      60.1142
    ],
    "date": "10.05.2020",
    "img": "2020_05_10_1"
  },
  {
    "name": "Осиновая Роща",
    "coord": [
      30.2612,
      60.1188
    ],
    "date": "17.05.2020",
    "img": "2020_05_17_1"
  },
  {
    "name": "Бугры, поле",
    "coord": [
      30.4133,
      60.0545
    ],
    "date": "18.05.2020",
    "img": "2020_05_18_1"
  },
  {
    "name": "Западный Котлин",
    "coord": [
      29.6595,
      60.0290
    ],
    "date": "24.05.2020",
    "img": "2020_05_24_1"
  },
  {
    "name": "Бугры, поле",
    "coord": [
      30.4172,
      60.0632
    ],
    "date": "27.05.2020",
    "img": "2020_05_27_1"
  },
  {
    "name": "Коккоревский заказник",
    "coord": [
      31.1093,
      60.0272
    ],
    "date": "30.05.2020",
    "img": "2020_05_30_1"
  },
  {
    "name": "Бугры, поле",
    "coord": [
      30.4138,
      60.0692
    ],
    "date": "01.06.2020",
    "img": "2020_06_01_1"
  },
  {
    "name": "Саблино",
    "coord": [
      30.7619,
      59.6245
    ],
    "date": "12.06.2020",
    "img": "2020_06_12_1"
  },
  {
    "name": "Сестрорецк",
    "coord": [
      29.94642,
      60.11523
    ],
    "date": "18.06.2020",
    "img": "2020_06_18_1"
  },
  {
    "name": "Лехтуси",
    "coord": [
      30.5981,
      60.2989
    ],
    "date": "28.06.2020",
    "img": "2020_06_28_1"
  },
  {
    "name": "Лес у Войтоловки",
    "coord": [
      31.0208,
      59.7081
    ],
    "date": "18.07.2020",
    "img": "2020_07_18_1"
  },
  {
    "name": "Славяночка, ДР Акимова",
    "coord": [
      30.3738,
      59.6664
    ],
    "date": "26.07.2020",
    "img": "2020_07_26_1"
  },
  {
    "name": "Саблино",
    "coord": [
      30.7676,
      59.6263
    ],
    "date": "15.08.2020",
    "img": "2020_08_15_1"
  },
  {
    "name": "Вольер зубробизонов",
    "coord": [
      30.45940,
      60.15430
    ],
    "date": "29.08.2020",
    "img": "2020_08_29_1"
  },
  {
    "name": "Лес за Лехтуси",
    "coord": [
      30.6102,
      60.3026
    ],
    "date": "03.09.2020",
    "img": "2020_09_03_1"
  },
  {
    "name": "Комарово",
    "coord": [
      29.7855,
      60.1774
    ],
    "date": "05.09.2020",
    "img": "2020_09_05_1"
  },
  {
    "name": "Коломна",
    "coord": [
      30.2912,
      59.9216
    ],
    "date": "06.09.2020",
    "img": "2020_09_06_1"
  },
  {
    "name": "Бугры",
    "coord": [
      30.4063,
      60.0664
    ],
    "date": "17.09.2020",
    "img": "2020_09_17_1"
  },
  {
    "name": "Рождествено",
    "coord": [
      29.94622,
      59.31656
    ],
    "date": "19.09.2020",
    "img": "2020_09_19_1"
  },
  {
    "name": "Борницкий карьер",
    "coord": [
      29.9370,
      59.4885
    ],
    "date": "19.09.2020",
    "img": "2020_09_19_2"
  },
  {
    "name": "Сестрорецкое болото",
    "coord": [
      30.0118,
      60.1216
    ],
    "date": "26.09.2020",
    "img": "2020_09_26_1"
  },
  {
    "name": "Озеро Светлое",
    "coord": [
      30.46945,
      60.14754
    ],
    "date": "30.09.2020",
    "img": "2020_09_30_1"
  },
  {
    "name": "Площадь Мужества",
    "coord": [
      30.3486,
      60.0038
    ],
    "date": "02.10.2020",
    "img": "2020_10_02_1"
  },
  {
    "name": "Выборг",
    "coord": [
      28.7193,
      60.7350
    ],
    "date": "03.10.2020",
    "img": "2020_10_03_1"
  },
  {
    "name": "Пушкин",
    "coord": [
      30.3693,
      59.7234
    ],
    "date": "10.10.2020",
    "img": "2020_10_10_1"
  },
  {
    "name": "Осиновая Роща",
    "coord": [
      30.25195,
      60.11297
    ],
    "date": "17.10.2020",
    "img": "2020_10_17_1"
  },
  {
    "name": "Оз. Светлое",
    "coord": [
      30.46750,
      60.14704
    ],
    "date": "31.10.2020",
    "img": "2020_10_31_1"
  },
  {
    "name": "Чкаловская",
    "coord": [
      30.2810,
      59.9631
    ],
    "date": "01.11.2020",
    "img": "2020_11_01_1"
  },
  {
    "name": "Центр",
    "coord": [
      30.3326,
      59.9400
    ],
    "date": "07.11.2020",
    "img": "2020_11_07_1"
  },
  {
    "name": "Приютино и Бернгардовка",
    "coord": [
      30.60710,
      60.00846
    ],
    "date": "08.11.2020",
    "img": "2020_11_08_1"
  },
  {
    "name": "Сярьги",
    "coord": [
      30.46712,
      60.14846
    ],
    "date": "15.11.2020",
    "img": "2020_11_15_1"
  },
  {
    "name": "Сярьги",
    "coord": [
      30.46439,
      60.14796
    ],
    "date": "21.11.2020",
    "img": "2020_11_21_1"
  },
  {
    "name": "Центр",
    "coord": [
      30.37729,
      59.93367
    ],
    "date": "28.11.2020",
    "img": "2020_11_28_1"
  },
  {
    "name": "Сярьги",
    "coord": [
      30.46802,
      60.14681
    ],
    "date": "06.12.2020",
    "img": "2020_12_06_1"
  },
  {
    "name": "Осиновая Роща",
    "coord": [
      30.2470,
      60.1179
    ],
    "date": "12.12.2020",
    "img": "2020_12_12_1"
  },
  {
    "name": "Сярьги, зубробизоны",
    "coord": [
      30.4576,
      60.1460
    ],
    "date": "19.12.2020",
    "img": "2020_12_19_1"
  },
  {
    "name": "Бугры",
    "coord": [
      30.3884,
      60.0661
    ],
    "date": "26.12.2020",
    "img": "2020_12_26_1"
  },
  {
    "name": "Центральное Мурино",
    "coord": [
      30.46522,
      60.03622
    ],
    "date": "27.12.2020",
    "img": "2020_12_27_1"
  },
  {
    "name": "Токсовские высоты",
    "coord": [
      30.5260,
      60.1669
    ],
    "date": "03.01.2021",
    "img": "2021_01_03_1"
  },
  {
    "name": "Центр",
    "coord": [
      30.3357,
      59.9537
    ],
    "date": "05.01.2021",
    "img": "2021_01_05_1"
  },
  {
    "name": "Саблино",
    "coord": [
      30.8074,
      59.6446
    ],
    "date": "06.01.2021",
    "img": "2021_01_06_1"
  },
  {
    "name": "Саблино",
    "coord": [
      30.76264,
      59.62141
    ],
    "date": "06.01.2021",
    "img": "2021_01_06_2"
  },
  {
    "name": "Лаврики",
    "coord": [
      30.4419,
      60.0755
    ],
    "date": "10.01.2021",
    "img": "2021_01_10_1"
  },
  {
    "name": "Сосновка",
    "coord": [
      30.3545,
      60.0271
    ],
    "date": "16.01.2021",
    "img": "2021_01_16_1"
  },
  {
    "name": "Пискарёвский парк",
    "coord": [
      30.3981,
      59.9989
    ],
    "date": "24.01.2021",
    "img": "2021_01_24_1"
  },
  {
    "name": "Вероярви",
    "coord": [
      30.5381,
      60.1502
    ],
    "date": "30.01.2021",
    "img": "2021_01_30_1"
  },
  {
    "name": "Осиновая Роща",
    "coord": [
      30.2486,
      60.1169
    ],
    "date": "31.01.2021",
    "img": "2021_01_31_1"
  },
  {
    "name": "Лаврики",
    "coord": [
      30.44869,
      60.07559
    ],
    "date": "06.02.2021",
    "img": "2021_02_06_1"
  },
  {
    "name": "Удельный парк",
    "coord": [
      30.3061,
      60.0076
    ],
    "date": "07.02.2021",
    "img": "2021_02_07_1"
  },
  {
    "name": "Осиновая Роща",
    "coord": [
      30.2566,
      60.1118
    ],
    "date": "13.02.2021",
    "img": "2021_02_13_1"
  },
  {
    "name": "Саблино",
    "coord": [
      30.7628,
      59.6300
    ],
    "date": "22.02.2021",
    "img": "2021_02_22_1"
  },
  {
    "name": "Центр",
    "coord": [
      30.2959,
      59.9348
    ],
    "date": "28.02.2021",
    "img": "2021_02_28_1"
  },
  {
    "name": "Лаврики",
    "coord": [
      30.4407,
      60.0714
    ],
    "date": "06.03.2021",
    "img": "2021_03_06_1"
  },
  {
    "name": "Мурино, поля",
    "coord": [
      30.4209,
      60.0605
    ],
    "date": "07.03.2021",
    "img": "2021_03_07_1"
  },
  {
    "name": "Озеро Вероярви",
    "coord": [
      30.5380,
      60.1503
    ],
    "date": "20.03.2021",
    "img": "2021_03_20_1"
  },
  {
    "name": "Токсово, совы",
    "coord": [
      30.54673,
      60.15572
    ],
    "date": "20.03.2021",
    "img": "2021_03_20_2"
  },
  {
    "name": "Осиновая Роща",
    "coord": [
      30.25579,
      60.11268
    ],
    "date": "27.03.2021",
    "img": "2021_03_27_1"
  },
  {
    "name": "Чёрная Речка",
    "coord": [
      30.28584,
      59.98720
    ],
    "date": "04.04.2021",
    "img": "2021_04_04_1"
  },
  {
    "name": "Юнтоловский",
    "coord": [
      30.18936,
      60.00156
    ],
    "date": "04.04.2021",
    "img": "2021_04_04_2"
  },
  {
    "name": "Юнтоловский",
    "coord": [
      30.1888,
      60.0261
    ],
    "date": "10.04.2021",
    "img": "2021_04_10_1"
  },
  {
    "name": "Юнтоловский",
    "coord": [
      30.1896,
      60.0029
    ],
    "date": "10.04.2021",
    "img": "2021_04_10_2"
  },
  {
    "name": "Бугры, балкон",
    "coord": [
      30.42396,
      60.06282
    ],
    "date": "15.04.2021",
    "img": "2021_04_15_1"
  },
  {
    "name": "Бугры, балкон",
    "coord": [
      30.42461,
      60.06460
    ],
    "date": "22.04.2021",
    "img": "2021_04_22_1"
  },
  {
    "name": "Ботанический сад",
    "coord": [
      30.31957,
      59.96843
    ],
    "date": "29.04.2021",
    "img": "2021_04_29_1"
  },
  {
    "name": "Сярьги",
    "coord": [
      30.46820,
      60.14639
    ],
    "date": "03.05.2021",
    "img": "2021_05_03_1"
  },
  {
    "name": "Бугры",
    "coord": [
      30.38748,
      60.06556
    ],
    "date": "03.05.2021",
    "img": "2021_05_03_2"
  },
  {
    "name": "Сярьги, зубробизоны",
    "coord": [
      30.4614,
      60.1548
    ],
    "date": "10.05.2021",
    "img": "2021_05_10_1"
  },
  {
    "name": "Сад «Прометей»",
    "coord": [
      30.38837,
      60.04006
    ],
    "date": "10.05.2021",
    "img": "2021_05_10_2"
  },
  {
    "name": "Осиновая роща",
    "coord": [
      30.2538,
      60.1140
    ],
    "date": "11.05.2021",
    "img": "2021_05_11_1"
  },
  {
    "name": "Пионерская площадь",
    "coord": [
      30.3380,
      59.9176
    ],
    "date": "16.05.2021",
    "img": "2021_05_16_1"
  },
  {
    "name": "Форт «Риф»",
    "coord": [
      29.63860,
      60.03324
    ],
    "date": "22.05.2021",
    "img": "2021_05_22_1"
  },
  {
    "name": "Лесная",
    "coord": [
      30.3503,
      59.9935
    ],
    "date": "28.05.2021",
    "img": "2021_05_28_1"
  },
  {
    "name": "Лесная",
    "coord": [
      30.3503,
      59.9935
    ],
    "date": "28.05.2021",
    "img": "2021_05_28_1"
  },
  {
    "name": "Сестрорецк",
    "coord": [
      29.95323,
      60.11170
    ],
    "date": "05.06.2021",
    "img": "2021_06_05_1"
  },
  {
    "name": "Саблинские пещеры",
    "coord": [
      30.79697,
      59.66726
    ],
    "date": "13.06.2021",
    "img": "2021_06_13_1"
  },
  {
    "name": "Сестрорецк",
    "coord": [
      29.9452,
      60.1154
    ],
    "date": "21.06.2021",
    "img": "2021_06_21_1"
  },
  {
    "name": "Приладожский",
    "coord": [
      31.30724,
      59.87367
    ],
    "date": "23.07.2021",
    "img": "2021_07_23_1"
  },
  {
    "name": "Лес за Лехтуси",
    "coord": [
      30.5939,
      60.2993  
    ],
    "date": "09.08.2021",
    "img": "2021_08_09_1"
  },
  {
    "name": "Войтолово",
    "coord": [
      31.0285,
      59.7027
    ],
    "date": "28.08.2021",
    "img": "2021_08_28_1"
  },
  {
    "name": "Вологда",
    "coord": [
      39.8826,
      59.2239
    ],
    "date": "18–19.09.2021",
    "img": "2021_09_18_1"
  },
  {
    "name": "Дибуны",
    "coord": [
      30.1202,
      60.1347
    ],
    "date": "26.09.2021",
    "img": "2021_09_26_1"
  },
  {
    "name": "Сортавала",
    "coord": [
      30.68985,
      61.70243
    ],
    "date": "02.10.2021",
    "img": "2021_10_02_1"
  },
  {
    "name": "Рускеала",
    "coord": [
      30.5786,
      61.9473
    ],
    "date": "02.10.2021",
    "img": "2021_10_02_2"
  },
  {
    "name": "Рускеальские водопады",
    "coord": [
      30.62689,
      61.91590
    ],
    "date": "02.10.2021",
    "img": "2021_10_02_3"
  },
  {
    "name": "Мийнала",
    "coord": [
      30.31063,
      61.54972
    ],
    "date": "02.10.2021",
    "img": "2021_10_02_4"
  },
  {
    "name": "Парк ЛТА",
    "coord": [
      30.3431,
      59.9919
    ],
    "date": "16.10.2021",
    "img": "2021_10_16_1"
  },
  {
    "name": "Мичуринское",
    "coord": [
      29.89422,
      60.57321
    ],
    "date": "17.10.2021",
    "img": "2021_10_17_1"
  },
  {
    "name": "Коломяги",
    "coord": [
      30.2907,
      60.0328
    ],
    "date": "23.10.2021",
    "img": "2021_10_23_1"
  },
  {
    "name": "Парголово",
    "coord": [
      30.28387,
      60.07640
    ],
    "date": "30.10.2021",
    "img": "2021_10_30_1"
  },
  {
    "name": "Бугры",
    "coord": [
      30.41847,
      60.06232
    ],
    "date": "28.11.2021",
    "img": "2021_11_28_1"
  },
  {
    "name": "Бугры",
    "coord": [
      30.41321,
      60.06014
    ],
    "date": "29.11.2021",
    "img": "2021_11_29_1"
  },
  {
    "name": "Центр",
    "coord": [
      30.3148,
      59.9327
    ],
    "date": "01.01.2022",
    "img": "2022_01_01_1"
  },
  {
    "name": "Этажи",
    "coord": [
      30.35557,
      59.92197
    ],
    "date": "04.01.2022",
    "img": "2022_01_04_1"
  },
  {
    "name": "Саблино",
    "coord": [
      30.75928,
      59.62600
    ],
    "date": "05.01.2022",
    "img": "2022_01_05_1"
  },
  {
    "name": "Ржевка",
    "coord": [
      30.5281,
      59.9855
    ],
    "date": "05.02.2022",
    "img": "2022_02_05_1"
  },
  {
    "name": "Осиновая Роща",
    "coord": [
      30.25768,
      60.11218
    ],
    "date": "12.02.2022",
    "img": "2022_02_12_1"
  },
  {
    "name": "Шишки на Лампушке",
    "coord": [
      30.11115,
      60.59059
    ],
    "date": "08.03.2022",
    "img": "2022_03_08_1"
  },
  {
    "name": "Вероярви",
    "coord": [
      30.53672,
      60.15108
    ],
    "date": "13.03.2022",
    "img": "2022_03_13_1"
  },
  {
    "name": "Центр",
    "coord": [
      30.36806,
      59.93759
    ],
    "date": "13.03.2022",
    "img": "2022_03_13_2"
  },
  {
    "name": "Петергоф",
    "coord": [
      29.90782,
      59.87534
    ],
    "date": "20.03.2022",
    "img": "2022_03_20_1"
  },
  {
    "name": "Бугры",
    "coord": [
      30.42123,
      60.06080
    ],
    "date": "30.03.2022",
    "img": "2022_03_30_1"
  },
  {
    "name": "Бугры",
    "coord": [
      30.2663,
      59.8894
    ],
    "date": "03.04.2022",
    "img": "2022_04_03_1"
  },
  {
    "name": "Академическая",
    "coord": [
      30.4232,
      60.0162
    ],
    "date": "16.04.2022",
    "img": "2022_04_16_1"
  },
  {
    "name": "Ботанический сад",
    "coord": [
      30.3207,
      59.9689
    ],
    "date": "01.05.2022",
    "img": "2022_05_01_1"
  },
  {
    "name": "Осиновая Роща",
    "coord": [
      30.2594,
      60.1196
    ],
    "date": "02.05.2022",
    "img": "2022_05_02_1"
  },
  {
    "name": "Воейково",
    "coord": [
      30.7061,
      59.9687
    ],
    "date": "07.05.2022",
    "img": "2022_05_07_1"
  },
    {
    "name": "Колтушские высоты",
    "coord": [
      30.6300,
      59.9590
    ],
    "date": "10.05.2022",
    "img": "2022_05_10_1"
  },
  {
    "name": "Елагин",
    "coord": [
      30.2650,
      59.9810
    ],
    "date": "14.05.2022",
    "img": "2022_05_14_1"
  },
  {
    "name": "Волковское кладбище",
    "coord": [
      30.36111,
      59.90416
    ],
    "date": "21.05.2022",
    "img": "2022_05_21_1"
  },
  {
    "name": "Ломоносовская",
    "coord": [
      30.4417,
      59.8759
    ],
    "date": "28.05.2022",
    "img": "2022_05_28_1"
  },
  {
    "name": "Полюстровский парк",
    "coord": [
      30.4220,
      59.9655
    ],
    "date": "03.06.2022",
    "img": "2022_06_03_1"
  },
  {
    "name": "Ивановский карьер",
    "coord": [
      30.42867,
      59.87821
    ],
    "date": "04.06.2022",
    "img": "2022_06_04_1"
  },
  {
    "name": "Ржевка",
    "coord": [
      30.50703,
      59.97790
    ],
    "date": "05.06.2022",
    "img": "2022_06_05_1"
  },
  {
    "name": "Сестрорецк",
    "coord": [
      29.96332,
      60.08946
    ],
    "date": "11.06.2022",
    "img": "2022_06_11_1"
  },
  {
    "name": "Саблино",
    "coord": [
      30.7578,
      59.6219
    ],
    "date": "12.06.2022",
    "img": "2022_06_12_1"
  },
  {
    "name": "Пискарёвский парк",
    "coord": [
      30.4164,
      60.0018
    ],
    "date": "14.06.2022",
    "img": "2022_06_14_1"
  },
  {
    "name": "Сосновка",
    "coord": [
      30.3596,
      60.0303
    ],
    "date": "19.06.2022",
    "img": "2022_06_19_1"
  },
  {
    "name": "Летний сад",
    "coord": [
      30.33397,
      59.94649
    ],
    "date": "22.06.2022",
    "img": "2022_06_22_1"
  },
  {
    "name": "Южно-Приморский парк",
    "coord": [
      30.16010,
      59.85365
    ],
    "date": "26.06.2022",
    "img": "2022_06_26_1"
  },
  {
    "name": "Бугры",
    "coord": [
      30.39747,
      60.05085
    ],
    "date": "29.06.2022",
    "img": "2022_06_29_1"
  },
  {
    "name": "Сергиевка",
    "coord": [
      29.84315,
      59.89470
    ],
    "date": "02.07.2022",
    "img": "2022_07_02_1"
  },
  {
    "name": "Павловск, садоводство Боде",
    "coord": [
      30.46072,
      59.69535
    ],
    "date": "03.07.2022",
    "img": "2022_07_03_1"
  },
  {
    "name": "Пески",
    "coord": [
      30.37600,
      59.93591
    ],
    "date": "08.07.2022",
    "img": "2022_07_08_1"
  },
  {
    "name": "Парк Есенина",
    "coord": [
      30.4858,
      59.9116
    ],
    "date": "08.07.2022",
    "img": "2022_07_08_2"
  },
  {
    "name": "Екатерингоф",
    "coord": [
      30.26464,
      59.90325
    ],
    "date": "09.07.2022",
    "img": "2022_07_09_1"
  },
  {
    "name": "Красное Село",
    "coord": [
      30.0978,
      59.7251
    ],
    "date": "09.07.2022",
    "img": "2022_07_09_2"
  },
  {
    "name": "Токсовские высоты",
    "coord": [
      30.5224,
      60.1666
    ],
    "date": "10.07.2022",
    "img": "2022_07_10_1"
  },
  {
    "name": "Аптекарская наб.",
    "coord": [
      30.32727,
      59.97409
    ],
    "date": "16.07.2022",
    "img": "2022_07_16_1"
  },
  {
    "name": "Поляны",
    "coord": [
      29.2859,
      60.3062
    ],
    "date": "17.07.2022",
    "img": "2022_07_17_1"
  },
  {
    "name": "Петергоф",
    "coord": [
      29.9123,
      59.8898
    ],
    "date": "24.07.2022",
    "img": "2022_07_24_1"
  },
  {
    "name": "Центр",
    "coord": [
      30.34604,
      59.94209
    ],
    "date": "26.07.2022",
    "img": "2022_07_26_1"
  },
  {
    "name": "Лес за Лехтуси",
    "coord": [
      30.6065,
      60.3023
    ],
    "date": "30.07.2022",
    "img": "2022_07_30_1"
  },
  {
    "name": "Разлив",
    "coord": [
      30.0115,
      60.0739
    ],
    "date": "31.07.2022",
    "img": "2022_07_31_1"
  },
  {
    "name": "Озеро Каменка",
    "coord": [
      30.8623,
      60.0882
    ],
    "date": "06.08.2022",
    "img": "2022_08_06_1"
  },
  {
    "name": "Орехово",
    "coord": [
      30.2813,
      60.4542
    ],
    "date": "07.08.2022",
    "img": "2022_08_07_1"
  },
  {
    "name": "Сосновка",
    "coord": [
      30.3540,
      60.0285
    ],
    "date": "07.08.2022",
    "img": "2022_08_07_2"
  },
  {
    "name": "Репино",
    "coord": [
      29.89112,
      60.15518
    ],
    "date": "12.08.2022",
    "img": "2022_08_12_1"
  },
  {
    "name": "Синявино",
    "coord": [
      31.1910,
      59.8975
    ],
    "date": "13.08.2022",
    "img": "2022_08_13_1"
  },
  {
    "name": "Разлив",
    "coord": [
      30.0053,
      60.0996
    ],
    "date": "14.08.2022",
    "img": "2022_08_14_1"
  },
  {
    "name": "Саблино",
    "coord": [
      30.74951,
      59.63118
    ],
    "date": "20.08.2022",
    "img": "2022_08_20_1"
  },
  {
    "name": "Зеленогорск",
    "coord": [
      29.7055,
      60.1961
    ],
    "date": "21.08.2022",
    "img": "2022_08_21_1"
  },
  {
    "name": "Выборгская",
    "coord": [
      30.3369,
      59.9721
    ],
    "date": "26.08.2022",
    "img": "2022_08_26_1"
  },
  {
    "name": "Лес за Грузино",
    "coord": [
      30.4824,
      60.4620
    ],
    "date": "27.08.2022",
    "img": "2022_08_27_1"
  },
  {
    "name": "Бухта Далёкая",
    "coord": [
      30.6857,
      60.5709
    ],
    "date": "27.08.2022",
    "img": "2022_08_27_2"
  },
  {
    "name": "Охта",
    "coord": [
      30.4424,
      59.9403
    ],
    "date": "03.09.2022",
    "img": "2022_09_03_1"
  },
  {
    "name": "Товарищеский",
    "coord": [
      30.4800,
      59.9173
    ],
    "date": "10.09.2022",
    "img": "2022_09_10_2"
  },
  {
    "name": "Петроградская",
    "coord": [
      30.3007,
      59.9733
    ],
    "date": "11.09.2022",
    "img": "2022_09_11_1"
  },
  {
    "name": "Музей Достоевского",
    "coord": [
      30.35069,
      59.92735
    ],
    "date": "17.09.2022",
    "img": "2022_09_17_1"
  },
  {
    "name": "Коломна",
    "coord": [
      30.27992,
      59.92547
    ],
    "date": "17.09.2022",
    "img": "2022_09_17_2"
  },
  {
    "name": "Люмьер-холл",
    "coord": [
      30.32896,
      59.91042
    ],
    "date": "21.09.2022",
    "img": "2022_09_21_1"
  },
  {
    "name": "Бугры, поля",
    "coord": [
      30.4198,
      60.0620
    ],
    "date": "24.09.2022",
    "img": "2022_09_24_1"
  },
  {
    "name": "Бугры, поля",
    "coord": [
      30.4187,
      60.0661
    ],
    "date": "08.10.2022",
    "img": "2022_10_08_1"
  },
  {
    "name": "Бугры, парк",
    "coord": [
      30.3879,
      60.0657
    ],
    "date": "15.10.2022",
    "img": "2022_10_15_1"
  },
  {
    "name": "Бугры, поля",
    "coord": [
      30.4123,
      60.0673
    ],
    "date": "22.10.2022",
    "img": "2022_10_22_1"
  },
  {
    "name": "Бугры, поля",
    "coord": [
      30.4179,
      60.0619
    ],
    "date": "29.10.2022",
    "img": "2022_10_29_1"
  },
  {
    "name": "Мурино",
    "coord": [
      30.4417,
      60.0642
    ],
    "date": "06.11.2022",
    "img": "2022_11_06_1"
  },
  {
    "name": "Бугры, поля",
    "coord": [
      30.4200,
      60.0621
    ],
    "date": "08.01.2023",
    "img": "2023_01_08_1"
  },
  {
    "name": "Мурино, балкон",
    "coord": [
      30.42252,
      60.05091
    ],
    "date": "21.01.2023",
    "img": "2023_01_21_1"
  },
  {
    "name": "Бугры, поля",
    "coord": [
      30.41718,
      60.06201
    ],
    "date": "05.02.2023",
    "img": "2023_02_05_1"
  },
  {
    "name": "Чёрная Речка",
    "coord": [
      30.2950,
      59.9860
    ],
    "date": "19.03.2023",
    "img": "2023_03_19_1"
  },
  {
    "name": "Бугры, поля",
    "coord": [
      30.41781,
      60.06063
    ],
    "date": "23.03.2023",
    "img": "2023_03_23_1"
  },
  {
    "name": "Озерки",
    "coord": [
      30.3203,
      60.0417
    ],
    "date": "02.04.2023",
    "img": "2023_04_02_1"
  },
  {
    "name": "Пр. Луначарского",
    "coord": [
      30.3790,
      60.0332
    ],
    "date": "09.04.2023",
    "img": "2023_04_09_1"
  },
  {
    "name": "Театр «Суббота»",
    "coord": [
      30.34355,
      59.91778
    ],
    "date": "19.04.2023",
    "img": "2023_04_19_1"
  },
  {
    "name": "Ботанический сад",
    "coord": [
      30.32601,
      59.97147
    ],
    "date": "22.04.2023",
    "img": "2023_04_22_1"
  },
  {
    "name": "Сосновка",
    "coord": [
      30.3639,
      60.0258
    ],
    "date": "29.04.2023",
    "img": "2023_04_29_1"
  },
  {
    "name": "Новоорловский лесопарк",
    "coord": [
      30.2887,
      60.0446
    ],
    "date": "01.05.2023",
    "img": "2023_05_01_1"
  },
  {
    "name": "Осиновая Роща",
    "coord": [
      30.2490,
      60.1171
    ],
    "date": "08.05.2023",
    "img": "2023_05_08_1"
  },
  {
    "name": "Бугры, поля",
    "coord": [
      30.4081,
      60.0557
    ],
    "date": "09.05.2023",
    "img": "2023_05_09_1"
  },
  {
    "name": "Кронштадт, Западный Котлин",
    "coord": [
      29.67370,
      60.02343
    ],
    "date": "13.05.2023",
    "img": "2023_05_13_1"
  },
  {
    "name": "Кронштадт, Северный форт",
    "coord": [
      29.7462,
      60.0257
    ],
    "date": "13.05.2023",
    "img": "2023_05_13_2"
  },
  {
    "name": "Кронштадт",
    "coord": [
      29.7769,
      59.9877
    ],
    "date": "13.05.2023",
    "img": "2023_05_13_3"
  },
  {
    "name": "Сосновка",
    "coord": [
      30.3560,
      60.0312
    ],
    "date": "14.05.2023",
    "img": "2023_05_14_1"
  },
  {
    "name": "Петроградская",
    "coord": [
      30.2960,
      59.9571
    ],
    "date": "21.05.2023",
    "img": "2023_05_21_1"
  },
  {
    "name": "Каменный остров",
    "coord": [
      30.2888,
      59.9794
    ],
    "date": "24.05.2023",
    "img": "2023_05_24_1"
  },
  {
    "name": "Нарвская",
    "coord": [
      30.2682,
      59.8964
    ],
    "date": "27.05.2023",
    "img": "2023_05_27_1"
  },
  {
    "name": "Елагин остров",
    "coord": [
      30.2591,
      59.9821
    ],
    "date": "27.05.2023",
    "img": "2023_05_27_2"
  },
  {
    "name": "Владимирский собор",
    "coord": [
      30.34851,
      59.92872
    ],
    "date": "28.05.2023",
    "img": "2023_05_28_1"
  },
  {
    "name": "ДК Моряков",
    "coord": [
      30.25293,
      59.91011
    ],
    "date": "30.05.2023",
    "img": "2023_05_30_1"
  },
  {
    "name": "Гарболово",
    "coord": [
      30.50043,
      60.34723
    ],
    "date": "04.06.2023",
    "img": "2023_06_04_1"
  },
  {
    "name": "Лахта-центр",
    "coord": [
      30.1804,
      59.9863
    ],
    "date": "10.06.2023",
    "img": "2023_06_10_1"
  },
  {
    "name": "Сярьги",
    "coord": [
      30.4611,
      60.1547
    ],
    "date": "11.06.2023",
    "img": "2023_06_11_1"
  },
  {
    "name": "Каменный остров",
    "coord": [
      30.30333,
      59.97905
    ],
    "date": "11.06.2023",
    "img": "2023_06_11_2"
  },
  {
    "name": "Саблино",
    "coord": [
      30.76130,
      59.62420
    ],
    "date": "17.06.2023",
    "img": "2023_06_17_1"
  },
  {
    "name": "Елизаровская",
    "coord": [
      30.4299,
      59.8977
    ],
    "date": "18.06.2023",
    "img": "2023_06_18_1"
  },
  {
    "name": "Елизаровская",
    "coord": [
      30.4271,
      59.8922
    ],
    "date": "18.06.2023",
    "img": "2023_06_18_2"
  },
  {
    "name": "Сад Крупской",
    "coord": [
      30.4415,
      59.8902
    ],
    "date": "18.06.2023",
    "img": "2023_06_18_3"
  },
  {
    "name": "Восточное Мурино",
    "coord": [
      30.4483,
      60.0328
    ],
    "date": "25.06.2023",
    "img": "2023_06_25_1"
  },
  {
    "name": "Ресторан F11",
    "coord": [
      30.29521,
      59.91433
    ],
    "date": "27.06.2023",
    "img": "2023_06_27_1"
  },
  {
    "name": "Пискарёвский парк",
    "coord": [
      30.4167,
      60.0018
    ],
    "date": "01.07.2023",
    "img": "2023_07_01_1"
  },
  {
    "name": "Серебряный пруд",
    "coord": [
      30.3454,
      60.0036
    ],
    "date": "06.07.2023",
    "img": "2023_07_06_1"
  },
  {
    "name": "Наб. Л-та Шмидта",
    "coord": [
      30.27254,
      59.93097
    ],
    "date": "08.07.2023",
    "img": "2023_07_08_1"
  },
  {
    "name": "Летний сад",
    "coord": [
      30.3352,
      59.9458
    ],
    "date": "14.07.2023",
    "img": "2023_07_14_1"
  },
  {
    "name": "Куммолово",
    "coord": [
      28.9937,
      59.6544
    ],
    "date": "16.07.2023",
    "img": "2023_07_16_1"
  },
  {
    "name": "Гревова",
    "coord": [
      29.0531,
      59.7074
    ],
    "date": "16.07.2023",
    "img": "2023_07_16_2"
  },
  {
    "name": "Лопухинка",
    "coord": [
      29.4027,
      59.7351
    ],
    "date": "16.07.2023",
    "img": "2023_07_16_3"
  },
  {
    "name": "Конная",
    "coord": [
      30.37516,
      59.92929
    ],
    "date": "20.07.2023",
    "img": "2023_07_20_1"
  },
  {
    "name": "Намыв на Васильевском",
    "coord": [
      30.1963,
      59.9359
    ],
    "date": "22.07.2023",
    "img": "2023_07_22_1"
  },
  {
    "name": "Наб. Л-та Шмидта",
    "coord": [
      30.27175,
      59.93039
    ],
    "date": "22.07.2023",
    "img": "2023_07_22_2"
  },
  {
    "name": "Лес за Лехтуси",
    "coord": [
      30.5922,
      60.2961
    ],
    "date": "23.07.2023",
    "img": "2023_07_23_1"
  },
  {
    "name": "Ладога на каяках",
    "coord": [
      30.0396,
      61.1607
    ],
    "date": "29.07.2023",
    "img": "2023_07_29_1"
  },
  {
    "name": "Парк Тихий Отдых",
    "coord": [
      30.28715,
      59.97859
    ],
    "date": "04.08.2023",
    "img": "2023_08_04_1"
  },
  {
    "name": "Сестрорецк",
    "coord": [
      29.9459,
      60.1102
    ],
    "date": "06.08.2023",
    "img": "2023_08_06_1"
  },
  {
    "name": "Выборгская",
    "coord": [
      30.3334,
      59.9781
    ],
    "date": "10.08.2023",
    "img": "2023_08_10_1"
  },
  {
    "name": "Вертолётная прогулка",
    "coord": [
      30.2387,
      59.9640
    ],
    "date": "12.08.2023",
    "img": "2023_08_12_1"
  },
  {
    "name": "Петровская коса",
    "coord": [
      30.24567,
      59.96126
    ],
    "date": "12.08.2023",
    "img": "2023_08_12_2"
  },
  {
    "name": "Матокса",
    "coord": [
      30.6019,
      60.2975
    ],
    "date": "13.08.2023",
    "img": "2023_08_13_1"
  },
  {
    "name": "Саблино",
    "coord": [
      30.7587,
      59.6270
    ],
    "date": "19.08.2023",
    "img": "2023_08_19_1"
  },
  {
    "name": "Прогулка на сапах",
    "coord": [
      30.28662,
      59.98008
    ],
    "date": "20.08.2023",
    "img": "2023_08_20_1"
  },
  {
    "name": "Прогулка на катере",
    "coord": [
      30.30774,
      59.97834
    ],
    "date": "26.08.2023",
    "img": "2023_08_26_1"
  },
  {
    "name": "Парк 300-летия",
    "coord": [
      30.20489,
      59.98221
    ],
    "date": "27.08.2023",
    "img": "2023_08_27_1"
  },
  {
    "name": "Юнтоловский",
    "coord": [
      30.1849,
      60.0306
    ],
    "date": "27.08.2023",
    "img": "2023_08_27_2"
  },
  {
    "name": "Лебяжье",
    "coord": [
      29.4295,
      59.9628
    ],
    "date": "03.09.2023",
    "img": "2023_09_03_1"
  },
  {
    "name": "Поляна Бианки",
    "coord": [
      29.4062,
      59.9672
    ],
    "date": "03.09.2023",
    "img": "2023_09_03_2"
  },
  {
    "name": "Красная Горка",
    "coord": [
      29.3371,
      59.9773
    ],
    "date": "03.09.2023",
    "img": "2023_09_03_3"
  },
  {
    "name": "Наб. Л-та Шмидта",
    "coord": [
      30.2735,
      59.9315
    ],
    "date": "03.09.2023",
    "img": "2023_09_03_4"
  },
  {
    "name": "Матокса",
    "coord": [
      30.6023,
      60.3397
    ],
    "date": "10.09.2023",
    "img": "2023_09_10_1"
  },
  {
    "name": "Думская башня",
    "coord": [
      30.32959,
      59.93470
    ],
    "date": "15.09.2023",
    "img": "2023_09_15_1"
  },
  {
    "name": "Канал Грибоедова",
    "coord": [
      30.32089,
      59.93111
    ],
    "date": "15.09.2023",
    "img": "2023_09_15_2"
  },
  {
    "name": "Извара",
    "coord": [
      29.5183,
      59.3526
    ],
    "date": "16.09.2023",
    "img": "2023_09_16_1"
  },
  {
    "name": "Усадьба Врангеля",
    "coord": [
      29.56713,
      59.51962
    ],
    "date": "16.09.2023",
    "img": "2023_09_16_2"
  },
  {
    "name": "Охта",
    "coord": [
      30.4063,
      59.9486
    ],
    "date": "17.09.2023",
    "img": "2023_09_17_1"
  },
  {
    "name": "Крестовский",
    "coord": [
      30.2107,
      59.9739
    ],
    "date": "22.09.2023",
    "img": "2023_09_22_1"
  },
  {
    "name": "Верёвочный парк",
    "coord": [
      30.31128,
      60.04089
    ],
    "date": "23.09.2023",
    "img": "2023_09_23_1"
  },
  {
    "name": "Ботанический сад",
    "coord": [
      30.32590,
      59.97115
    ],
    "date": "23.09.2023",
    "img": "2023_09_23_2"
  },
  {
    "name": "Достоевская",
    "coord": [
      30.3455,
      59.9225
    ],
    "date": "29.09.2023",
    "img": "2023_09_29_1"
  },
  {
    "name": "Пискарёвский парк",
    "coord": [
      30.3455,
      59.9225
    ],
    "date": "30.09.2023",
    "img": "2023_09_30_1"
  },
  {
    "name": "Мичуринское",
    "coord": [
      29.8905,
      60.5715
    ],
    "date": "21.10.2023",
    "img": "2023_10_21_1"
  },
  {
    "name": "Михайловский сад",
    "coord": [
      30.3321,
      59.9391
    ],
    "date": "22.10.2023",
    "img": "2023_10_22_1"
  },
  {
    "name": "Бугры",
    "coord": [
      30.38811,
      60.06581
    ],
    "date": "28.10.2023",
    "img": "2023_10_28_1"
  },
  {
    "name": "Осиновая Роща",
    "coord": [
      30.2517,
      60.1145
    ],
    "date": "06.11.2023",
    "img": "2023_11_06_1"
  },
  {
    "name": "Севкабель",
    "coord": [
      30.2488,
      59.9225
    ],
    "date": "11.11.2023",
    "img": "2023_11_11_1"
  },
  {
    "name": "Сосновка",
    "coord": [
      30.3564,
      60.0288
    ],
    "date": "03.12.2023",
    "img": "2023_12_03_1"
  },
  {
    "name": "Новая Голландия",
    "coord": [
      30.2877,
      59.9294
    ],
    "date": "01.01.2024",
    "img": "2024_01_01_1"
  },
  {
    "name": "Центр",
    "coord": [
      30.3244,
      59.9519
    ],
    "date": "20.01.2024",
    "img": "2024_01_20_1"
  },
  {
    "name": "Музей Зощенко",
    "coord": [
      30.32636,
      59.93793
    ],
    "date": "03.02.2024",
    "img": "2024_02_03_1"
  },
  {
    "name": "Академия Штиглица",
    "coord": [
      30.34063,
      59.94297
    ],
    "date": "10.02.2024",
    "img": "2024_02_10_1"
  },
  {
    "name": "Ботанический сад",
    "coord": [
      30.3236,
      59.9702
    ],
    "date": "25.02.2024",
    "img": "2024_02_25_1"
  },
  {
    "name": "Охта",
    "coord": [
      30.4141,
      59.9473
    ],
    "date": "02.03.2024",
    "img": "2024_03_02_1"
  },
  {
    "name": "Выборгская",
    "coord": [
      30.3381,
      59.9815
    ],
    "date": "03.03.2024",
    "img": "2024_03_03_1"
  },
  {
    "name": "Выборг",
    "coord": [
      28.7257,
      60.7142
    ],
    "date": "08.03.2024",
    "img": "2024_03_08_1"
  },
  {
    "name": "Сосновка",
    "coord": [
      30.3542,
      60.0287
    ],
    "date": "11.04.2024",
    "img": "2024_04_11_1"
  },
  {
    "name": "Фонтанка",
    "coord": [
      30.3242,
      59.9238
    ],
    "date": "01.05.2024",
    "img": "2024_05_01_1"
  },
  {
    "name": "Канонерский",
    "coord": [
      30.2062,
      59.8918
    ],
    "date": "04.05.2024",
    "img": "2024_05_04_1"
  },
  {
    "name": "Пискарёвский",
    "coord": [
      30.4176,
      60.0020
    ],
    "date": "05.05.2024",
    "img": "2024_05_05_1"
  },
  {
    "name": "Иловые площадки ЮЗОС",
    "coord": [
      30.1439,
      59.8141
    ],
    "date": "09.05.2024",
    "img": "2024_05_09_1"
  },
  {
    "name": "Осиновая Роща",
    "coord": [
      30.24837,
      60.11308
    ],
    "date": "10.05.2024",
    "img": "2024_05_10_1"
  },
  {
    "name": "Юкки",
    "coord": [
      30.30563,
      60.11425
    ],
    "date": "11.05.2024",
    "img": "2024_05_11_1"
  },
  {
    "name": "Шуваловский парк",
    "coord": [
      30.3040,
      60.0710
    ],
    "date": "12.05.2024",
    "img": "2024_05_12_1"
  },
  {
    "name": "Театр на Фонтанке",
    "coord": [
      30.31277,
      59.91870
    ],
    "date": "17.05.2024",
    "img": "2024_05_17_1"
  },
  {
    "name": "Экотропа Лазурные воды",
    "coord": [
      30.3699,
      59.1601
    ],
    "date": "18.05.2024",
    "img": "2024_05_18_1"
  },
  {
    "name": "ЦПКиО",
    "coord": [
      30.2676,
      59.9769
    ],
    "date": "19.05.2024",
    "img": "2024_05_19_1"
  },
  {
    "name": "Екатерингоф",
    "coord": [
      30.2561,
      59.9039
    ],
    "date": "26.05.2024",
    "img": "2024_05_26_1"
  },
  {
    "name": "Экотропа Малиновая Гора",
    "coord": [
      30.4824,
      60.1677
    ],
    "date": "01.06.2024",
    "img": "2024_06_01_1"
  },
  {
    "name": "Большая Ижора",
    "coord": [
      29.56806,
      59.95149
    ],
    "date": "02.06.2024",
    "img": "2024_06_02_1"
  },
  {
    "name": "Софиевка",
    "coord": [
      30.6236,
      60.0078
    ],
    "date": "03.06.2024",
    "img": "2024_06_03_1"
  },
  {
    "name": "Шуваловский парк",
    "coord": [
      30.3050,
      60.0746
    ],
    "date": "09.06.2024",
    "img": "2024_06_09_1"
  },
  {
    "name": "Парк ЛТА",
    "coord": [
      30.33414,
      59.99667
    ],
    "date": "12.06.2024",
    "img": "2024_06_12_1"
  },
  {
    "name": "Курголовская этнотропа",
    "coord": [
      30.5390,
      60.1864
    ],
    "date": "16.06.2024",
    "img": "2024_06_16_1"
  },
  {
    "name": "Квадроциклы",
    "coord": [
      30.4556,
      60.1510
    ],
    "date": "21.06.2024",
    "img": "2024_06_21_1"
  },
  {
    "name": "Батарейная Бухта",
    "coord": [
      29.1144,
      59.9672
    ],
    "date": "22.06.2024",
    "img": "2024_06_22_1"
  },
  {
    "name": "Большая Ижора",
    "coord": [
      29.56781,
      59.95213
    ],
    "date": "22.06.2024",
    "img": "2024_06_22_2"
  },
  {
    "name": "Острова",
    "coord": [
      30.2425,
      59.9720
    ],
    "date": "23.06.2024",
    "img": "2024_06_23_1"
  },
  {
    "name": "Еврейское кладбище",
    "coord": [
      30.4522,
      59.8600
    ],
    "date": "29.06.2024",
    "img": "2024_06_29_1"
  },
  {
    "name": "Петроградская",
    "coord": [
      30.2947,
      59.9689
    ],
    "date": "30.06.2024",
    "img": "2024_06_30_1"
  },
  {
    "name": "Лахта-центр",
    "coord": [
      30.1726,
      59.9860
    ],
    "date": "07.07.2024",
    "img": "2024_07_07_1"
  },
  {
    "name": "Гражданская",
    "coord": [
      30.31208,
      59.92846
    ],
    "date": "07.07.2024",
    "img": "2024_07_07_2"
  },
  {
    "name": "Исаакиевская площадь",
    "coord": [
      30.30902,
      59.93152
    ],
    "date": "07.07.2024",
    "img": "2024_07_07_3"
  },
  {
    "name": "Кавголово",
    "coord": [
      30.52767,
      60.17593
    ],
    "date": "08.07.2024",
    "img": "2024_07_08_1"
  },
  {
    "name": "Долина реки Охта",
    "coord": [
      30.44054,
      60.14557
    ],
    "date": "14.07.2024",
    "img": "2024_07_14_1"
  },
  {
    "name": "Кавголово",
    "coord": [
      30.52868,
      60.17173
    ],
    "date": "17.07.2024",
    "img": "2024_07_17_1"
  },
  {
    "name": "Архангельск, набережная",
    "coord": [
      40.5565,
      64.5266
    ],
    "date": "20.07.2024",
    "img": "2024_07_20_1"
  },
  {
    "name": "Архангельск, причал",
    "coord": [
      40.5248,
      64.5317
    ],
    "date": "20.07.2024",
    "img": "2024_07_20_2"
  },
  {
    "name": "Архангельск, Соломбала",
    "coord": [
      40.5030,
      64.5781
    ],
    "date": "20.07.2024",
    "img": "2024_07_20_3"
  },
  {
    "name": "Архангельск, музей Плотниковой",
    "coord": [
      40.52197,
      64.53349
    ],
    "date": "21.07.2024",
    "img": "2024_07_21_1"
  },
  {
    "name": "Малые Корелы",
    "coord": [
      40.9391,
      64.4538
    ],
    "date": "21.07.2024",
    "img": "2024_07_21_2"
  },
  {
    "name": "Архангельск, Чумбаровка",
    "coord": [
      40.52188,
      64.53740
    ],
    "date": "22.07.2024",
    "img": "2024_07_22_1"
  },
  {
    "name": "Сапы, центр",
    "coord": [
      30.3318,
      59.9415
    ],
    "date": "28.07.2024",
    "img": "2024_07_28_1"
  },
  {
    "name": "Острова",
    "coord": [
      30.282716,
      59.978542
    ],
    "date": "28.07.2024",
    "img": "2024_07_28_2"
  },
  {
    "name": "Линдуловская роща",
    "coord": [
      29.536517,
      60.234121
    ],
    "date": "03.08.2024",
    "img": "2024_08_03_1"
  },
  {
    "name": "Зеленогорск, пляж",
    "coord": [
      29.6454155,
      60.1913081
    ],
    "date": "03.08.2024",
    "img": "2024_08_03_2"
  },
  {
    "name": "Выборгская",
    "coord": [
      30.33973,
      59.97504
    ],
    "date": "06.08.2024",
    "img": "2024_08_06_1"
  },
  {
    "name": "Таврический",
    "coord": [
      30.375073,
      59.945205
    ],
    "date": "07.08.2024",
    "img": "2024_08_07_1"
  },
  {
    "name": "Сосновка",
    "coord": [
      30.36267,
      60.02838
    ],
    "date": "10.08.2024",
    "img": "2024_08_10_1"
  },
  {
    "name": "Матокса",
    "coord": [
      30.61263,
      60.29562
    ],
    "date": "11.08.2024",
    "img": "2024_08_11_1"
  },
  {
    "name": "Касл-рок",
    "coord": [
      30.358762,
      59.929828
    ],
    "date": "15.08.2024",
    "img": "2024_08_15_1"
  },
  {
    "name": "Саблино",
    "coord": [
      30.799999,
      59.670997
    ],
    "date": "17.08.2024",
    "img": "2024_08_17_1"
  },
  {
    "name": "Технологический институт",
    "coord": [
      30.32400,
      59.91401
    ],
    "date": "20.08.2024",
    "img": "2024_08_20_1"
  },
  {
    "name": "Матокса",
    "coord": [
      30.60169,
      60.30091
    ],
    "date": "24.08.2024",
    "img": "2024_08_24_1"
  },
  {
    "name": "Обводный",
    "coord": [
      30.342854,
      59.916709
    ],
    "date": "27.08.2024",
    "img": "2024_08_27_1"
  },
  {
    "name": "Шуваловский парк",
    "coord": [
      30.30553,
      60.07289
    ],
    "date": "01.09.2024",
    "img": "2024_09_01_1"
  },
  {
    "name": "Чкаловская",
    "coord": [
      30.296624,
      59.962573
    ],
    "date": "02.09.2024",
    "img": "2024_09_02_1"
  },
  {
    "name": "Нарвская",
    "coord": [
      30.28102,
      59.89897
    ],
    "date": "03.09.2024",
    "img": "2024_09_03_1"
  },
  {
    "name": "Паруса на крыше",
    "coord": [
      30.315533,
      59.965235
    ],
    "date": "05.09.2024",
    "img": "2024_09_05_1"
  },
  {
    "name": "Ломоносов",
    "coord": [
      29.769983,
      59.914141
    ],
    "date": "07.09.2024",
    "img": "2024_09_07_1"
  },
  {
    "name": "Парк Ораниенбаум",
    "coord": [
      29.744325,
      59.916575
    ],
    "date": "07.09.2024",
    "img": "2024_09_07_2"
  },
  {
    "name": "Липово",
    "coord": [
      29.05676,
      59.93199
    ],
    "date": "07.09.2024",
    "img": "2024_09_07_3"
  },
  {
    "name": "Сосновка",
    "coord": [
      30.354345,
      60.029068
    ],
    "date": "09.09.2024",
    "img": "2024_09_09_1"
  },
  {
    "name": "Таврическая улица",
    "coord": [
      30.37780,
      59.94147
    ],
    "date": "10.09.2024",
    "img": "2024_09_10_1"
  },
  {
    "name": "Осиновая Роща",
    "coord": [
      30.26416,
      60.11985
    ],
    "date": "14.09.2024",
    "img": "2024_09_14_1"
  },
  {
    "name": "Светлое озеро",
    "coord": [
      29.89161,
      60.59325
    ],
    "date": "21.09.2024",
    "img": "2024_09_21_1"
  },
  {
    "name": "Лисино-Корпус",
    "coord": [
      30.684686,
      59.422717
    ],
    "date": "29.09.2024",
    "img": "2024_09_29_1"
  },
  {
    "name": "Вырица",
    "coord": [
      30.33226,
      59.41704
    ],
    "date": "29.09.2024",
    "img": "2024_09_29_2"
  },
  {
    "name": "Мичуринское",
    "coord": [
      29.88616,
      60.57862
    ],
    "date": "03.10.2024",
    "img": "2024_10_03_1"
  },
  {
    "name": "Выборг",
    "coord": [
      28.70122,
      60.74551
    ],
    "date": "05.10.2024",
    "img": "2024_10_05_1"
  },
  {
    "name": "Пушкин",
    "coord": [
      30.38986,
      59.71855
    ],
    "date": "06.10.2024",
    "img": "2024_10_06_1"
  },
  {
    "name": "Лаврики",
    "coord": [
      30.45163,
      60.07194
    ],
    "date": "04.11.2024",
    "img": "2024_11_04_1"
  },
  {
    "name": "Осиновая Роща",
    "coord": [
      30.24944,
      60.11395
    ],
    "date": "10.11.2024",
    "img": "2024_11_10_1"
  },
  {
    "name": "Ботанический сад",
    "coord": [
      30.32119,
      59.96879
    ],
    "date": "15.12.2024",
    "img": "2024_12_15_1"
  },
  {
    "name": "Бар Дуглас",
    "coord": [
      30.343423,
      59.958381
    ],
    "date": "18.12.2024",
    "img": "2024_12_18_1"
  },
  {
    "name": "Петергоф",
    "coord": [
      29.90697,
      59.88231
    ],
    "date": "31.12.2024",
    "img": "2024_12_31_1"
  },
  {
    "name": "Пушкин",
    "coord": [
      30.38960,
      59.71082
    ],
    "date": "01.01.2025",
    "img": "2025_01_01_1"
  },
  {
    "name": "Пушкин",
    "coord": [
      30.38960,
      59.71082
    ],
    "date": "01.01.2025",
    "img": "2025_01_01_1"
  },
  {
    "name": "Новгород",
    "coord": [
      31.28473,
      58.52335
    ],
    "date": "02.01.2025",
    "img": "2025_01_02_1"
  },
  {
    "name": "Новгород, Красное поле",
    "coord": [
      31.304877,
      58.526366
    ],
    "date": "03.01.2025",
    "img": "2025_01_03_1"
  },
  {
    "name": "Новгород, Торговая сторона",
    "coord": [
      31.293955,
      58.517106
    ],
    "date": "03.01.2025",
    "img": "2025_01_03_2"
  },
  {
    "name": "Кировск",
    "coord": [
      30.98501,
      59.88668
    ],
    "date": "04.01.2025",
    "img": "2025_01_04_1"
  },
  {
    "name": "Шлиссельбург",
    "coord": [
      31.033899,
      59.945001
    ],
    "date": "04.01.2025",
    "img": "2025_01_04_2"
  },
  {
    "name": "Саблино",
    "coord": [
      30.765255,
      59.621755
    ],
    "date": "05.01.2025",
    "img": "2025_01_05_1"
  },
  {
    "name": "Павловск",
    "coord": [
      30.453314,
      59.685902
    ],
    "date": "06.01.2025",
    "img": "2025_01_06_1"
  },
  {
    "name": "Музей Буллы",
    "coord": [
      30.336129,
      59.934481
    ],
    "date": "07.01.2025",
    "img": "2025_01_07_1"
  },
  {
    "name": "Клуб Factory 3",
    "coord": [
      30.240854,
      59.925110
    ],
    "date": "11.01.2025",
    "img": "2025_01_11_1"
  },
  {
    "name": "Гатчина, парк",
    "coord": [
      30.115378,
      59.565815
    ],
    "date": "18.01.2025",
    "img": "2025_01_18_1"
  },
  {
    "name": "Гатчина",
    "coord": [
      30.125131,
      59.566354
    ],
    "date": "18.01.2025",
    "img": "2025_01_18_1"
  },
  {
    "name": "Елагин остров",
    "coord": [
      30.253083,
      59.978722
    ],
    "date": "19.01.2025",
    "img": "2025_01_19_1"
  },
  {
    "name": "Эрарта",
    "coord": [
      30.251193,
      59.932119
    ],
    "date": "25.01.2025",
    "img": "2025_01_25_1"
  },
  {
    "name": "Новая Голландия",
    "coord": [
      30.286536,
      59.929641
    ],
    "date": "25.01.2025",
    "img": "2025_01_25_2"
  },
  {
    "name": "Ивановский карьер",
    "coord": [
      30.423641,
      59.879066
    ],
    "date": "01.02.2025",
    "img": "2025_02_01_1"
  },
  {
    "name": "Елизаровская",
    "coord": [
      30.429243,
      59.893314
    ],
    "date": "01.02.2025",
    "img": "2025_02_01_2"
  },
  {
    "name": "ДК Крупской",
    "coord": [
      30.429243,
      59.893314
    ],
    "date": "01.02.2025",
    "img": "2025_02_01_3"
  },
  {
    "name": "Саблино",
    "coord": [
      30.755191,
      59.622532
    ],
    "date": "02.02.2025",
    "img": "2025_02_02_1"
  },
  {
    "name": "Новая Ладога",
    "coord": [
      32.316683,
      60.105207
    ],
    "date": "08.02.2025",
    "img": "2025_02_08_1"
  },
  {
    "name": "Старая Ладога",
    "coord": [
      32.296317,
      59.997233
    ],
    "date": "08.02.2025",
    "img": "2025_02_08_2"
  },
  {
    "name": "Никитилово",
    "coord": [
      30.506533,
      60.329522
    ],
    "date": "09.02.2025",
    "img": "2025_02_09_1"
  },
  {
    "name": "Кингисепп",
    "coord": [
      28.598448,
      59.374535
    ],
    "date": "23.02.2025",
    "img": "2025_02_23_1"
  },
  {
    "name": "Утешение",
    "coord": [
      28.997326,
      59.550310
    ],
    "date": "23.02.2025",
    "img": "2025_02_23_2"
  },
  {
    "name": "Смоленск, центр",
    "coord": [
      32.053677,
      54.784001
    ],
    "date": "08.03.2025",
    "img": "2025_03_08_1"
  },
  {
    "name": "Смоленск, ул. Николаева",
    "coord": [
      32.043603,
      54.774039
    ],
    "date": "08.03.2025",
    "img": "2025_03_08_2"
  },
  {
    "name": "Смоленск, стена",
    "coord": [
      32.063530,
      54.782447
    ],
    "date": "08.03.2025",
    "img": "2025_03_08_3"
  },
  {
    "name": "Смоленск, вокзал",
    "coord": [
      32.043655,
      54.797425
    ],
    "date": "09.03.2025",
    "img": "2025_03_09_1"
  },
  {
    "name": "Смоленск, Днепр",
    "coord": [
      32.038854,
      54.791135
    ],
    "date": "09.03.2025",
    "img": "2025_03_09_2"
  },
  {
    "name": "Смоленск, Красный ручей",
    "coord": [
      32.057927,
      54.783835
    ],
    "date": "09.03.2025",
    "img": "2025_03_09_3"
  },
  {
    "name": "Смоленск, костёл",
    "coord": [
      32.053265,
      54.773406
    ],
    "date": "09.03.2025",
    "img": "2025_03_09_4"
  }
  


  
  
  
  
  
  
  
  
  
  

];
